import gql from 'graphql-tag';
import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import { setLoaderStart, setLoaderComplete } from '../loader/loader'
import showToaster from '../../helpers/showToaster';

export default function updateHomepageSettingsCity(values) {

    return async (dispatch, getState, { client }) => {

        dispatch({
            type: HOMEPAGE_SETTINGS_UPLOAD_START
        })

        try {

            const mutation = gql`
            mutation updateHomePageCity (
                $citySectionTitle1: String
                $citySectionContent1: String
              ) {
                updateHomePageCity (
                  citySectionTitle1: $citySectionTitle1
                  citySectionContent1: $citySectionContent1
                ) {
                  status
                }
              }
            `
            dispatch(setLoaderStart('CitySettingsForm'))
            const { data } = await client.mutate({
                mutation,
                variables: {
                    citySectionTitle1: values && values.citySectionTitle1,
                    citySectionContent1: values && values.citySectionContent1
                }
            })

            dispatch(setLoaderComplete('CitySettingsForm'))
            if (data && data.updateHomePageCity && data.updateHomePageCity.status == 200) {

                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                })
                showToaster({ messageId: 'updateHomePageSettings', toasterType: 'success' })
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                })
                showToaster({ messageId: 'commonError', toasterType: 'error' })
            }
        } catch (err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            })
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
        }

    }
}