import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { siteSettings } from '../../../actions/siteadmin/siteSettings'
import { injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import cx from 'classnames';

import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';
import Loader from '../../Common/Loader';

import messages from '../../../locale/messages'
import submit from './submit'
import validate from './validate'
import { api, logoUploadDir, faviconUploadDir, siteUrl, ogImageUploadDir } from '../../../config';

import defalutImage from '../../../../public/Icons/defalutImage.svg';

import s from './SiteSettingsForm.css';
import bt from '../../../components/commonStyle.css';

export class SiteSettingsForm extends Component {
  static defaultProps = {
    loading: false
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div>
            <label className={bt.labelText} >{label}</label>
          </div>
          <div>
            <FormControl {...input} placeholder={label} type={type} className={bt.formControlInput} maxlength={maxlength} />
            {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }
  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div>
            <label className={bt.labelText} >{label}</label>
          </div>
          <div>
            <FormControl
              {...input}
              className={className}
              placeholder={label}
              as="textarea"
              rows="3"
            >
              {children}
            </FormControl>
            {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, logo, loading, submitting, appForceUpdate, favicon, favImageLoader, logoImageLoader, ogImageLoader, ogImage } = this.props;
    const waitingTime = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let logoImage = logo ? api.apiEndpoint + logoUploadDir + logo : defalutImage;
    let faviconImage = favicon ? siteUrl + faviconUploadDir + favicon : defalutImage;
    let ogLogoImage = ogImage ? api.apiEndpoint + ogImageUploadDir + ogImage : defalutImage;
    return (
      <div>
        <div>
          <Container fluid>
            <form onSubmit={handleSubmit(submit)}>
              <Row className='siteSettingFormRow'>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className={cx(s.marbtm14, bt.spaceTop2)}>
                  <div className={s.profileImgSection}>
                    <ImageUploadComponent
                      subText={formatMessage(messages.maximumUploadSizeLabel)}
                      defaultMessage={formatMessage(messages.chooseFile)}
                      loaderName={'logoImageLoader'}
                      postUrl={api.apiEndpoint + '/uploadLogoImage'}
                      loader={logoImageLoader}
                      fieldName={'homeLogo'}
                      formName={'SiteSettingsForm'}
                      imageSrc={logoImage}
                      label={formatMessage(messages.logo)}
                      inputContainer={'.dzInputContainerLogo'}
                      inputContainerClass={cx('dzInputContainerLogo', 'dzInputContainerLogoOpacity')}
                      defaultImage={!logo ? true : false}
                    />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className={cx(s.marbtm14, bt.spaceTop2)}>
                  <div className={s.profileImgSection}>
                    <ImageUploadComponent
                      subText={formatMessage(messages.maximumUploadSizeLabel)}
                      defaultMessage={formatMessage(messages.chooseFile)}
                      loaderName={'favImageLoader'}
                      postUrl={siteUrl + '/uploadFavicon'}
                      loader={favImageLoader}
                      fieldName={'favicon'}
                      formName={'SiteSettingsForm'}
                      imageSrc={faviconImage}
                      label={formatMessage(messages.favIconlogoLabel)}
                      inputContainer={'.dzInputContainerLogo'}
                      inputContainerClass={cx('dzInputContainerLogo', 'dzInputContainerLogoOpacity')}
                      defaultImage={!faviconImage ? true : false}
                    />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className={cx(s.marbtm14, bt.spaceTop2)}>
                  <div className={s.profileImgSection}>
                    <ImageUploadComponent
                      subText={formatMessage(messages.maximumUploadSizeLabel)}
                      defaultMessage={formatMessage(messages.chooseFile)}
                      loaderName={'ogImageLoader'}
                      postUrl={api.apiEndpoint + '/uploadOgImage'}
                      loader={ogImageLoader}
                      fieldName={'ogImage'}
                      formName={'SiteSettingsForm'}
                      imageSrc={ogLogoImage}
                      label={formatMessage(messages.ogImageLabel)}
                      inputContainer={'.dzInputContainerLogo'}
                      inputContainerClass={cx('dzInputContainerLogo', 'dzInputContainerLogoOpacity')}
                      defaultImage={!ogLogoImage ? true : false}
                      toolTip={formatMessage(messages.ogImageTooltip)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="siteName" type="text" component={this.renderFormControl} label={formatMessage(messages.siteName)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="siteTitle" type="text" component={this.renderFormControl} label={formatMessage(messages.siteTitle)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="metaKeyword" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metakey)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="metaDescription" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaDesc)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="facebookLink" type="text" component={this.renderFormControl} label={'Facebook URL'} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twitterLink" type="text" component={this.renderFormControl} label={'Twitter URL'} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="instagramLink" type="text" component={this.renderFormControl} label={'Instagram URL'} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="youtubeLink" type="text" component={this.renderFormControl} label={'Youtube URL'} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="maxUploadSize" type="text" component={this.renderFormControl} label={formatMessage(messages.maxUploadLabel)} />
                </Col>
              </Row>

              <div className={cx(bt.textAlignRight, 'textAlignLeftRTL', 'loadingBtnRTL', s.bottomBtnPadding, 'bottomBtnPaddingRTL')}>
                <FormGroup className={s.formGroup}>
                  <Loader
                    type={"button"}
                    label={formatMessage(messages.submitButton)}
                    show={loading}
                    buttonType={'submit'}
                    className={cx(bt.btnPrimary)}
                    disabled={submitting || loading}
                    isSuffix={true}
                  />
                </FormGroup>
              </div>

            </form>
          </Container>
        </div>
      </div>
    )
  }
}
const callSiteSettings = async (result, dispatch, props) => {
  const { refetch } = props
  await refetch()
  dispatch(siteSettings())
}
SiteSettingsForm = reduxForm({
  form: 'SiteSettingsForm',
  onSubmit: submit,
  validate,
  onSubmitSuccess: callSiteSettings
})(SiteSettingsForm);

const selector = formValueSelector('SiteSettingsForm')

const mapState = (state) => ({
  logo: selector(state, 'homeLogo'),
  favicon: selector(state, 'favicon'),
  ogImage: selector(state, 'ogImage'),
  appForceUpdate: selector(state, 'appForceUpdate'),
  loading: state.loader.SiteSettings,
  favImageLoader: state.loader.favImageLoader,
  logoImageLoader: state.loader.logoImageLoader,
  ogImageLoader: state.loader.ogImageLoader
})
const mapDispatch = {
  siteSettings
}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(SiteSettingsForm)));
