import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import { api, staticpageUploadDir } from '../../../config';
import submit from './submit';
import validate from './validate';
import messages from '../../../locale/messages';
import { removeStaticBannerImage } from '../../../actions/siteadmin/PrecautionNotification/updatePrecautionNotification';
import { updatePrecautionNotificationImage } from '../../../actions/siteadmin/PrecautionNotification/updatePrecautionNotificationImage';
import { updateTempImages } from '../../../actions/siteadmin/TempImages/updateTempImages';

import defaultIcon from '../../../../public/Icons/defalutImage.svg';

import s from './PrecautionNotificationForm.css';
import bt from '../../../components/commonStyle.css';

export class PrecautionNotificationForm extends Component {

    constructor(props) {
        super(props)
        if (typeof window !== 'undefined') {
            this.ReactQuill = require('react-quill')
        }
    }

    handleDeleteImage = async (event) => {
        const { change, id, imageName, isEnabled, updatePrecautionNotificationImage } = this.props;
        const { removeStaticBannerImage } = this.props;
        let oldImage = imageName;
        await change('imageName', null);
        if (id) { (updatePrecautionNotificationImage({ id, imageName: null }, oldImage)) }
        else {
            await updateTempImages('PrecautionNotification', 'imageName', null);
            await removeStaticBannerImage(oldImage);
        }
    }

    renderFormControl = ({ input, label, type, meta: { touched, error } }) => {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <FormGroup className={s.formGroup}>
                    <div>
                        <label className={bt.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl {...input} placeholder={label} type={type} className={bt.formControlInput} />
                        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    renderQuill = ({ input, label, type, meta: { touched, error } }) => {
        const ReactQuill = this.ReactQuill;
        const { formatMessage } = this.props.intl;
        let modules = {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link'],
                // ['link', 'image'],
            ],
            clipboard: {
                matchVisual: false,
            }
        };
        let formats = [
            'header', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link'
            // 'link', 'image'
        ];
        return (
            <div>
                <ReactQuill
                    {...input}
                    onChange={(newValue, delta, source) => {
                        if (source === 'user') {
                            input.onChange(newValue);
                        }
                    }}
                    onBlur={(range, source, quill) => {
                        if (quill.getHTML() == '<p><br></p>') {
                            input.onBlur('');
                        }
                        else {
                            input.onBlur(quill.getHTML());
                        }
                    }}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                />

                {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
            </div>
        );
    }


    handleDropzone = async (fileName, fieldName) => {
        const { change, id, imageName } = this.props;
        const { updateTempImages, updatePrecautionNotificationImage, removeStaticBannerImage } = this.props;
        let oldImage = imageName;
        await change(fieldName, fileName);
        if (id) {
            updatePrecautionNotificationImage({ id, imageName: fileName }, oldImage)
        } else {
            await updateTempImages('PrecautionNotification', fieldName, fileName);
            if (oldImage) await removeStaticBannerImage(oldImage);
        }
    }

    render() {
        const { handleSubmit, imageName, imageLoader } = this.props;
        const { formatMessage } = this.props.intl;
        const ReactQuill = this.ReactQuill;
        let image = imageName ? api.apiEndpoint + staticpageUploadDir + 'medium_' + imageName : defaultIcon;
        if (typeof window !== 'undefined' && ReactQuill) {
            return (
                <div className={cx('cardSection', s.widthInner, bt.space5, s.responsiveNoPadding, s.widthInnerTwo, 'bgBlackTwo')}>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.space2, s.paddingTop)}>
                                <FormGroup className={s.formGroup}>
                                    <div className={cx(s.profileImgSection)}>
                                        <ImageUploadComponent
                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                            defaultMessage={formatMessage(messages.chooseFile)}
                                            loaderName={'imageLoader'}
                                            postUrl={api.apiEndpoint + '/uploadStaticBannerImage'}
                                            loader={imageLoader}
                                            fieldName={'imageName'}
                                            imageSrc={image}
                                            label={formatMessage(messages.image)}
                                            inputContainer={'.dzInputContainer'}
                                            inputContainerClass={'dzInputContainer'}
                                            handleDropzone={this.handleDropzone}
                                            handleDelete={this.handleDeleteImage}
                                            isRemove={imageName ? true : false}
                                            defaultImage={!imageName ? true : false}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <div>
                                    <Form.Group className={s.formGroup}>
                                        <Field
                                            name="title"
                                            type="text"
                                            placeholder={formatMessage(messages.title)}
                                            component={this.renderFormControl}
                                            label={formatMessage(messages.title)}
                                            labelClass={bt.labelText}
                                            fieldClass={bt.formControlInput}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <Form.Group className={s.formGroup}>
                                    <div>
                                        <label className={bt.labelText} >{formatMessage(messages.status)}</label>
                                        <Field name="isEnabled" className={cx(bt.formControlSelect, bt.formControlInput, s.selectBox)} component="select">
                                            <option value={true}>{formatMessage(messages.Enabled)}</option>
                                            <option value={false}>{formatMessage(messages.Disabled)}</option>
                                        </Field>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup className={s.formGroup}>
                                    <div>
                                        <label className={bt.labelText} ><FormattedMessage {...messages.description} /></label>
                                    </div>
                                    <div xs={12} sm={9} md={9} lg={9}>
                                        <Field name="description" component={this.renderQuill} onChange={(event) => { }} />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, s.paddingBottom, 'textAlignLeftRTL')}>
                            <Form.Group className={s.formGroup}>
                                <Button
                                    type="submit"
                                    className={cx(bt.btnPrimary)}
                                >
                                    {formatMessage(messages.submitButton)}
                                </Button>
                            </Form.Group>
                        </Col>
                    </Form>
                </div>
            );
        } else {
            return <span></span>;
        }
    }
}

PrecautionNotificationForm = reduxForm({
    form: 'PrecautionNotificationForm',
    validate
})(PrecautionNotificationForm);

const selector = formValueSelector('PrecautionNotificationForm')

const mapState = (state) => ({
    id: selector(state, 'id'),
    title: selector(state, 'title'),
    description: selector(state, 'description'),
    isEnabled: selector(state, 'isEnabled'),
    imageName: selector(state, 'imageName'),
    imageLoader: state.loader.imageLoader

})

const mapDispatch = {
    change,
    updatePrecautionNotificationImage,
    removeStaticBannerImage,
    updateTempImages
}

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PrecautionNotificationForm)));