import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import messages from '../../../locale/messages';
import submit from './submit';
import validate from './validate';
import { api, homepageUploadDir } from '../../../config';

import defaultIcon from '../../../../public/Icons/defalutImage.svg';

import s from './FooterSettingsForm.css';
import bt from '../../../components/commonStyle.css';

export class FooterSettingsForm extends Component {
	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<label className={bt.labelText} >{label}</label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={bt.formControlInput} />
				{touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}
	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<FormGroup className={s.formGroup}>
					<div>
						<label className={bt.labelText} >{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={className}
							placeholder={label}
							as="textarea"
							rows="3"
						>
							{children}
						</FormControl>
						{touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}
	render() {
		const { handleSubmit, footerLogo1, footerLogo2, footerLogo3, footerLogo4, loading, submitting, footerLogo1Loader } = this.props;
		const { formatMessage } = this.props.intl;
		let logo1 = footerLogo1 ? api.apiEndpoint + homepageUploadDir + footerLogo1 : defaultIcon;
		return (
			<div className={cx('cardSection', s.widthInner, bt.space5, s.responsiveNoPadding, s.widthInnerTwo, 'bgBlackTwo')}>
				<Row>
					<Col md={12} lg={12} sm={12} xs={12} className={s.responsiveNoPadding}>
						<Card className={s.card}>
							<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
								<Col lg={12} md={12} sm={12} xs={12}>
									<h1 className='textAlignRightRTL'>{formatMessage(messages.footerSectionSettings)}</h1>
								</Col>
								<Row>
									<Col lg={6} md={12} sm={12} xs={12} className={bt.space2}>
										<Form.Group className={s.formGroup}>
											<div className={cx(s.profileImgSection, s.profileImgWidth)}>
												<div>
													<ImageUploadComponent
														subText={formatMessage(messages.maximumUploadSizeLabel)}
														defaultMessage={formatMessage(messages.chooseFile)}
														loaderName={'footerLogo1Loader'}
														postUrl={api.apiEndpoint + '/uploadHomepageImage'}
														loader={footerLogo1Loader}
														fieldName={'footerLogo1'}
														formName={'FooterSettingsForm'}
														imageSrc={logo1}
														inputContainer={'.dzInputContainerFooterImage1'}
														inputContainerClass={'dzInputContainerFooterImage1'}
														label={formatMessage(messages.footerLogo1)}
														defaultImage={!footerLogo1 ? true : false}
													/>
												</div>
											</div>
										</Form.Group>
									</Col>
									<Col lg={6} md={12} sm={12} xs={12} className={bt.space2}>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="footerTitle1" type="text" component={this.renderField} label={formatMessage(messages.footerTitle1)} />
											</div>
										</Form.Group>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="footerContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.footerContent1)} />
											</div>
										</Form.Group>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="footerLinkTitle" type="text" component={this.renderField} label={formatMessage(messages.footerUrltitle)} />
											</div>
										</Form.Group>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="footerBottom" type="text" component={this.renderField} label={formatMessage(messages.copyRightcontent)} />
											</div>
										</Form.Group>
									</Col>
								</Row>
								<Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL', 'loadingBtnRTL')}>
									<Form.Group className={s.formGroup}>
										<div className={s.displayInlineBlock}>
											<Loader
												type={"button"}
												label={formatMessage(messages.submitButton)}
												show={loading}
												buttonType={'submit'}
												className={cx(bt.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Col>
							</Form>
						</Card>
					</Col>
				</Row>
			</div>
		)
	}
}
FooterSettingsForm = reduxForm({
	form: 'FooterSettingsForm',
	onSubmit: submit,
	validate
})(FooterSettingsForm);
const selector = formValueSelector('FooterSettingsForm')
const mapState = (state) => ({
	footerLogo1: selector(state, 'footerLogo1'),
	footerLogo2: selector(state, 'footerLogo2'),
	footerLogo3: selector(state, 'footerLogo3'),
	footerLogo4: selector(state, 'footerLogo4'),
	loading: state.loader.SignupSettingsForm,
	footerLogo1Loader: state.loader.footerLogo1Loader
})
const mapDispatch = {

}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(FooterSettingsForm)));
