import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Faq from '../../../../public/Icons/question_info.svg';

const ToolTipContent = ({ label }) => {
    return (
        <>
            <OverlayTrigger overlay={<Popover className='imageUploadTooltipContainer'>{label}</Popover>}>
                <img src={Faq} />
            </OverlayTrigger>
        </>
    )
}

export default ToolTipContent;
