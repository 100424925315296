import fetch from 'node-fetch';
import showToaster from '../../../../helpers/showToaster';
import history from '../../../../history';

async function submit(values, dispatch) {

  if (values.content == null || values.content == '<p><br></p>' || values.content == '<p> </p>') {
    showToaster({ messageId: 'addContent', toasterType: 'error' })
  } else {
    const mutation = `
          mutation addContentPageDetails(
            $id: Int,
            $metaTitle: String,
            $metaDescription: String,
            $pageUrl: String,
            $pageTitle: String,
            $content: String,
            $pageBanner: String
          ) {
            addContentPageDetails(
              id: $id,
              metaTitle: $metaTitle,
              metaDescription: $metaDescription,
              pageUrl: $pageUrl,
              pageTitle: $pageTitle,
              content: $content,
              pageBanner: $pageBanner
            ) {
                status
            }
          }
          `;

    const response = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: mutation,
        variables: values
      }),
      credentials: 'include'
    });

    const { data } = await response.json();


    if (data && data.addContentPageDetails && data.addContentPageDetails.status === "success") {
      showToaster({ messageId: 'addContentPage', toasterType: 'success' })
      history.push('/siteadmin/contentpage/manage')
    }
    else if (data && data.addContentPageDetails && data.addContentPageDetails.status === 'URL exist') {
      showToaster({ messageId: 'existUrl', toasterType: 'error' })
    }
    else {
      showToaster({ messageId: 'addContentPageError', toasterType: 'error' })
    }
  }
}

export default submit;