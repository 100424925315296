import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Header.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import NavLink from '../NavLink';
import messages from '../../locale/messages';
import Link from '../Link';
import { connect } from 'react-redux';
import cx from 'classnames';
import { api, logoUploadDir } from '../../config'
import { flowRight as compose } from 'lodash';
import history from '../../history';
import HeaderModal from '../HeaderModal/HeaderModal';
import { openHeaderModal } from '../../actions/siteadmin/modalActions';
import { choseToTheme } from '../../actions/currency/getCurrencyRates';

import { formatLocale } from '../../helpers/formatLocale';
import LanguageIcon from '../../../public/Icons/languageIcon.svg';
//images
import selectedLightIcon from '../../../public/Icons/lightModeOn.svg';
import selectedDarkIcon from '../../../public/Icons/darkModeOn.svg';
import dropDownLightIcon from '../../../public/Icons/lightModeOff.svg';
import dropDownDarkIcon from '../../../public/Icons/darkModeOff.svg';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
    };
    this.openMenu = this.openMenu.bind(this);
    this.openClose = this.openClose.bind(this);
  }



  async openMenu() {
    this.setState({
      isOpen: 1,
    })
  }
  async openClose() {
    this.setState({
      isOpen: 0,
    })
  }

  handleChange(e) {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  }



  render() {
    let location;

    if (history.location) {
      location = history.location.pathname;
    }



    const { logo, logoHeight, logoWidth, siteName, isOpen, currentLocale, selectedTheme, choseToTheme } = this.props;
    return (
      <div className={cx(s.root, 'mainMenu')}>
        <HeaderModal />
        <Navbar expand="lg" className={cx(s.navBg, { ['homeHeader']: location === '/' || location === '/home' }, 'bgBlackTwo')}>
          <Navbar.Brand>
            <Link className={s.brand} to="/">
              <img
                src={api.apiEndpoint + logoUploadDir + logo}
                className={s.logoImg}
                alt={siteName}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={cx(s.borderNone, s.outlineNone)}
            children={
              <div className={'menuToggle'} onClick={() => this.openMenu()}>
                {/* <input type="checkbox" /> */}
                <span></span>
                <span></span>
                <span></span>
                {/* &#9776; */}
              </div>
            }
          />
          <Navbar.Collapse className={cx({ [s.menuOpened]: this.state.isOpen == 1 }, s.justifyFlexEnd, s.menuClosed, 'bgBlackTwo')} in={isOpen} id="basic-navbar-nav">

            <Nav className={cx(s.navigationLink, 'navigationLinkDark', 'bgBlackTwo')} onClick={() => this.openClose()}>
              <div className={cx(s.closeButton, 'closeButtonHomeRTL')}> &#x2715; </div>
              <NavDropdown title={
                <>
                  {
                    (selectedTheme && selectedTheme === "light") &&
                    <span className={s.selectedThemeColor}> <img src={selectedLightIcon} alt='' /> <FormattedMessage {...messages.lightMode} /></span>
                  }
                  {
                    (selectedTheme && selectedTheme === "dark") &&
                    <span className={s.selectedThemeColorDark}> <img src={selectedDarkIcon} alt='' /> <FormattedMessage {...messages.darkMode} /></span>
                  }
                </>
              }

                noCaret id="basic-nav-dropdown1" className={cx(s.nonBreakPointScreen, 'themeIconDropDown', 'd-none  d-lg-block d-xl-block')}>
                <NavDropdown.Item to="#" onClick={() => { choseToTheme('light') }} className={s.themeIconDropDownLink}>
                  <div className={cx({ [s.selectedThemeColor]: selectedTheme === "light" }, s.themeIconSec)}>
                    {
                      (selectedTheme && selectedTheme === "light") ?
                        <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> : <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                    }
                    <FormattedMessage {...messages.lightMode} />
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item to="#" onClick={() => { choseToTheme('dark') }} className={s.themeIconDropDownLink}>
                  <div className={cx({ [s.selectedThemeColorDark]: selectedTheme === "dark" }, s.themeIconSec)}>
                    {
                      (selectedTheme && selectedTheme === "dark") ?
                        <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> : <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                    }
                    <FormattedMessage {...messages.darkMode} />
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
              <NavLink noLink
                onClick={(e) => this.handleChange(e)}
                className={cx(s.siteColor, s.cursurPointer)}>
                <span className={cx(s.displayInlineBlock, s.vtrTop, s.iconWidth, 'svgImg')}>
                  <img src={LanguageIcon} className={cx(s.sideMenuIcon, s.noFilter, 'homeHeaderIconRTL')} />
                </span>
                <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, 'iconTextPaddingRTL')}>
                  {formatLocale(currentLocale)}
                </span>
              </NavLink>
              <NavLink to="/" ><FormattedMessage {...messages.homeonly} /></NavLink>
              <NavLink to="/rider" ><FormattedMessage {...messages.rider} /></NavLink>
              <NavLink to="/driver" ><FormattedMessage {...messages.driver} /></NavLink>
              <NavLink to="/support" ><FormattedMessage {...messages.contact} /></NavLink>
              <NavLink to="#" onClick={() => { choseToTheme('light') }} className={cx('d-block d-sm-block d-md-block d-lg-none d-xl-none')}>
                <span className={cx(s.themeIconAlignment, { [s.selectedThemeColor]: selectedTheme === "light" })}>
                  <FormattedMessage {...messages.lightMode} />
                  {
                    selectedTheme && selectedTheme === "light" ?
                      <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                      <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                  }
                </span>
              </NavLink>
              <NavLink to="#" onClick={() => { choseToTheme('dark') }} className={cx('d-block d-sm-block d-md-block d-lg-none d-xl-none')}>
                <span className={cx(s.themeIconAlignment, { [s.selectedThemeColor]: selectedTheme === "dark" })}>
                  <FormattedMessage {...messages.darkMode} />
                  {
                    selectedTheme && selectedTheme === "dark" ?
                      <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                      <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                  }
                </span>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

const mapState = (state) => ({
  logo: state.siteSettings.data.homeLogo,
  logoHeight: state.siteSettings.data.logoHeight,
  logoWidth: state.siteSettings.data.logoWidth,
  siteName: state.siteSettings.data.siteName,
  currentLocale: state.intl.locale,
  selectedTheme: state.currency.theme
})
const mapDispatch = {
  openHeaderModal,
  choseToTheme
}

export default compose(
  withStyles(s),
  connect(mapState, mapDispatch)
)(Header)
