import {
    DELETE_LOCATION_START,
    DELETE_LOCATION_ERROR,
    DELETE_LOCATION_SUCCESS
} from '../../constants';
import gql from 'graphql-tag';
import showToaster from '../../helpers/showToaster';

export function deleteLocation(id, currentPage) {
    return async (dispatch, getState, { client }) => {

        dispatch({
            type: DELETE_LOCATION_START
        });

        try {
            let query = gql`
            query getLocationList($currentPage: Int, $searchList: String){
                getLocationList(currentPage: $currentPage, searchList:$searchList){
                count
                LocationData{
                    id
                    locationName
                    description
                    isActive
                }
                }
            }
            `
            let mutation = gql`
            mutation deleteLocation($id: Int) {
                deleteLocation(id: $id) {
                  status
                  errorMessage
                }
              }
            `
            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                },
                refetchQueries: [{ query, variables: { currentPage, searchList: '' } }]
            });



            if (data && data.deleteLocation && data.deleteLocation.status == 200) {
                dispatch({
                    type: DELETE_LOCATION_SUCCESS
                })
                showToaster({ messageId: 'deleteLocation', toasterType: 'success' })
            } else {

                dispatch({
                    type: DELETE_LOCATION_ERROR
                })

                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deleteLocation?.errorMessage })
            }

        } catch (err) {
            dispatch({
                type: DELETE_LOCATION_ERROR
            })
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
        }
    }
}