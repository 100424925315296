import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import messages from '../../../locale/messages';
import validate from './validate';
import { api, homepageUploadDir } from '../../../config';

import defaultIcon from '../../../../public/Icons/defalutImage.svg';

import s from './SignupSettingsForm.css';
import bt from '../../../components/commonStyle.css';

export class SignupSettingsForm extends Component {
    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <label className={bt.labelText} >{label}</label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={bt.formControlInput} />
                {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }
    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.formGroup}>
                    <div>
                        <label className={bt.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="3"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }
    render() {
        const { handleSubmit, signupGridImage1, signupGridImage2, signupGridImage3, loading, submitting } = this.props;
        const { signupGridImage3Loader, signupGridImage1Loader, signupGridImage2Loader } = this.props;
        const { formatMessage } = this.props.intl;
        let signupImage3, signupImage1, signupImage2;
        signupImage3 = signupGridImage3 ? api.apiEndpoint + homepageUploadDir + signupGridImage3 : defaultIcon;
        signupImage1 = signupGridImage1 ? api.apiEndpoint + homepageUploadDir + signupGridImage1 : defaultIcon;
        signupImage2 = signupGridImage2 ? api.apiEndpoint + homepageUploadDir + signupGridImage2 : defaultIcon;
        return (
            <div className={cx('cardSection', s.widthInner, bt.space5, s.responsiveNoPadding, s.widthInnerTwo, 'bgBlackTwo')}>
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12} className={s.responsiveNoPadding}>
                        <Card className={s.card}>
                            <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h1 className='textAlignRightRTL'>{formatMessage(messages.signupSectionSettings)}</h1>
                                </Col>
                                <Row>
                                    <Col lg={4} md={12} sm={12} xs={12} className={bt.space2}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'signupGridImage3Loader'}
                                                    postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                    loader={signupGridImage3Loader}
                                                    fieldName={'signupGridImage3'}
                                                    formName={'SignupSettingsForm'}
                                                    imageSrc={signupImage3}
                                                    inputContainer={'.dzInputContainerSignupImage3'}
                                                    inputContainerClass={'dzInputContainerSignupImage3'}
                                                    label={formatMessage(messages.signupImage3)}
                                                    defaultImage={!signupGridImage3 ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={12} sm={12} xs={12} className={bt.space2}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'signupGridImage1Loader'}
                                                    postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                    loader={signupGridImage1Loader}
                                                    fieldName={'signupGridImage1'}
                                                    formName={'SignupSettingsForm'}
                                                    imageSrc={signupImage1}
                                                    inputContainer={'.dzInputContainerSignupImage1'}
                                                    inputContainerClass={'dzInputContainerSignupImage1'}
                                                    label={formatMessage(messages.signupImage1)}
                                                    defaultImage={!signupGridImage1 ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={12} sm={12} xs={12} className={bt.space2}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'signupGridImage2Loader'}
                                                    postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                    loader={signupGridImage2Loader}
                                                    fieldName={'signupGridImage2'}
                                                    formName={'SignupSettingsForm'}
                                                    imageSrc={signupImage2}
                                                    inputContainer={'.dzInputContainerSignupImage2'}
                                                    inputContainerClass={'dzInputContainerSignupImage2'}
                                                    label={formatMessage(messages.signupImage2)}
                                                    defaultImage={!signupGridImage2 ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="signupGridTitle1" type="text" component={this.renderField} label={formatMessage(messages.driverapptitle)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="signupGridContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.driverappcntn)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="signupGridLink1" type="text" component={this.renderField} label={formatMessage(messages.playstorelink)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="signupGridLink2" type="text" component={this.renderField} label={formatMessage(messages.appstorelink)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                                    <Form.Group className={s.formGroup}>
                                        <div className={s.displayInlineBlock}>
                                            <Loader
                                                type={"button"}
                                                label={formatMessage(messages.submitButton)}
                                                show={loading}
                                                buttonType={'submit'}
                                                className={cx(bt.btnPrimary)}
                                                disabled={submitting || loading}
                                                isSuffix={true}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
SignupSettingsForm = reduxForm({
    form: 'SignupSettingsForm',
    onSubmit: submit,
    validate
})(SignupSettingsForm);
const selector = formValueSelector('SignupSettingsForm')
const mapState = (state) => ({
    signupGridImage1: selector(state, 'signupGridImage1'),
    signupGridImage2: selector(state, 'signupGridImage2'),
    signupGridImage3: selector(state, 'signupGridImage3'),
    loading: state.loader.SignupSettingsForm,
    signupGridImage3Loader: state.loader.signupGridImage3Loader,
    signupGridImage1Loader: state.loader.signupGridImage1Loader,
    signupGridImage2Loader: state.loader.signupGridImage2Loader
})
const mapDispatch = {

}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(SignupSettingsForm)));
