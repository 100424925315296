import gql from 'graphql-tag';
import { change } from 'redux-form';
import { closeCancelBookingModal, closeCancelManualBookingModal } from '../modalActions';
import query from '../../../routes/site-admin/viewBooking/viewBookingDetails.graphql';
import getAllBookings from '../../../components/Booking/BookingList/getAllBookings.graphql';
import showToaster from '../../../helpers/showToaster';

const mutation = gql`
mutation ($bookingId: Int!, $requestBy: String!, $reason: String) {
  cancelBooking(bookingId: $bookingId, requestBy: $requestBy, reason: $reason) {
    status
    errorMessage
  }
}
`;

export default function cancelBooking(variables) {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch(change("CancelBookingForm", "loading", true));
            const { data } = await client.mutate({
                mutation,
                variables,
                refetchQueries: [{ query, variables: { id: variables.bookingId } }, { query: getAllBookings, variables: { currentPage: variables.currentPage, bookingType: 1 } }]
            });

            if (data && data.cancelBooking && data.cancelBooking.status === 200) {
                showToaster({ messageId: 'cancelBooking', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.cancelBooking?.errorMessage })
            }
            dispatch(closeCancelBookingModal())
            dispatch(closeCancelManualBookingModal())
            dispatch(change("CancelBookingForm", "loading", false));
            return true;
        }
        catch (error) {
            dispatch(change("CancelBookingForm", "loading", false));
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            return false;
        }
    };
}