import gql from 'graphql-tag';
import {
    USER_DELETE_SUCCESS,
    USER_DELETE_START,
    USER_DELETE_ERROR
} from '../../constants/index';
import showToaster from '../../helpers/showToaster';

export default function deleteUser(profileId, currentPage, userType) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: USER_DELETE_START,
            payload: {
                deleteLoading: true
            }
        });

        try {
            let query;

            if (userType === 2) {
                query = gql`
                query getAllDrivers($currentPage: Int){
                    getAllDrivers(currentPage: $currentPage){
                      count
                      userData {
                        email
                        phoneDialCode
                        phoneNumber
                        isBan
                        userStatus
                        createdAt
                        profile {
                          profileId
                          firstName
                          lastName
                          country
                        }
                      }   
                    }
                  }
                `;
            } else {
                query = gql`
                query getAllRiders($currentPage: Int){
                    getAllRiders(currentPage: $currentPage){
                     count
                      userData {
                        email
                        phoneDialCode
                        phoneNumber
                        isBan
                        userStatus
                        createdAt
                        profile {
                          profileId
                          firstName
                          lastName
                          country
                        }
                      }
                    }
                  }
                `;
            }


            let mutation = gql`
            mutation deleteUser($profileId: Int) {
                deleteUser(profileId: $profileId) {
                  status
                  errorMessage
                }
              }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    profileId
                },
                refetchQueries: [{ query, variables: { currentPage } }]
            });

            if (data && data.deleteUser && data.deleteUser.status === "200") {
                dispatch({
                    type: USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: userType === 2 ? 'deleteDriver' : 'deleteRider', toasterType: 'success' })
            } else {
                dispatch({
                    type: USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deleteUser?.errorMessage })
            }
        } catch (err) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
            dispatch({
                type: USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
        }

    }
};