import { addCategory } from '../../../actions/siteadmin/addCategory';
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {
    if (!values.categoryImage) {
        showToaster({ messageId: 'categoryImage', toasterType: 'error' })
        return;
    }

    if (!values.categoryMarkerImage) {
        showToaster({ messageId: 'categoryMarkerImage', toasterType: 'error' })
        return;
    }

    await dispatch(
        addCategory(
            values.categoryName,
            values.capacity,
            values.isActive,
            values.categoryImage,
            values.categoryMarkerImage
        )
    )
}

export default submit;