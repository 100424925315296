import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, formatMessage } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import messages from '../../../locale/messages';
import validate from './validate';
import { api, homepageUploadDir } from '../../../config';

import defaultIcon from '../../../../public/Icons/defalutImage.svg';

import s from './SafetySettingsForm.css';
import bt from '../../../components/commonStyle.css';

export class SafetySettingsForm extends Component {
    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <label className={bt.labelText} >{label}</label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={bt.formControlInput} />
                {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }
    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.formGroup}>
                    <div>
                        <label className={bt.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="3"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }
    render() {
        const { handleSubmit, safetyGridImage1, safetyGridImage2, safetyGridImage3, loading, submitting } = this.props;
        const { safetyGridImage3Loader, safetyGridImage1Loader, safetyGridImage2Loader } = this.props;
        const { formatMessage } = this.props.intl;
        let gridImage3, gridImage1, gridImage2;
        gridImage3 = safetyGridImage3 ? api.apiEndpoint + homepageUploadDir + safetyGridImage3 : defaultIcon;
        gridImage1 = safetyGridImage1 ? api.apiEndpoint + homepageUploadDir + safetyGridImage1 : defaultIcon;
        gridImage2 = safetyGridImage2 ? api.apiEndpoint + homepageUploadDir + safetyGridImage2 : defaultIcon;

        return (
            <div className={cx('cardSection', s.widthInner, bt.space5, s.responsiveNoPadding, s.widthInnerTwo, 'bgBlackTwo')}>
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12} className={s.responsiveNoPadding}>
                        <Card className={s.card}>
                            <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h1 className='textAlignRightRTL'>{formatMessage(messages.safetySectionSettings)}</h1>
                                </Col>
                                <Row>
                                    <Col lg={4} md={12} sm={12} xs={12} className={bt.space2}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'safetyGridImage3Loader'}
                                                    postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                    loader={safetyGridImage3Loader}
                                                    fieldName={'safetyGridImage3'}
                                                    formName={'SafetySettingsForm'}
                                                    imageSrc={gridImage3}
                                                    inputContainer={'.dzInputContainerSafetyImage3'}
                                                    inputContainerClass={'dzInputContainerSafetyImage3'}
                                                    label={formatMessage(messages.safetyImage3)}
                                                    defaultImage={!safetyGridImage3 ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={12} sm={12} xs={12} className={bt.space2}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'safetyGridImage1Loader'}
                                                    postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                    loader={safetyGridImage1Loader}
                                                    fieldName={'safetyGridImage1'}
                                                    formName={'SafetySettingsForm'}
                                                    imageSrc={gridImage1}
                                                    inputContainer={'.dzInputContainerSafetyImage1'}
                                                    inputContainerClass={'dzInputContainerSafetyImage1'}
                                                    label={formatMessage(messages.safetyImage1)}
                                                    defaultImage={!safetyGridImage1 ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={12} sm={12} xs={12} className={bt.space2}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'safetyGridImage2Loader'}
                                                    postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                    loader={safetyGridImage2Loader}
                                                    fieldName={'safetyGridImage2'}
                                                    formName={'SafetySettingsForm'}
                                                    imageSrc={gridImage2}
                                                    inputContainer={'.dzInputContainerSafetyImage2'}
                                                    inputContainerClass={'dzInputContainerSafetyImage2'}
                                                    label={formatMessage(messages.safetyImage2)}
                                                    defaultImage={!safetyGridImage2 ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="safetyGridTitle1" type="text" component={this.renderField} label={formatMessage(messages.riderApptitle)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="safetyGridContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.riderAppContent)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="safetyGridLink1" type="text" component={this.renderField} label={formatMessage(messages.playstorelink)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="safetyGridLink2" type="text" component={this.renderField} label={formatMessage(messages.appstorelink)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                                    <Form.Group className={s.formGroup}>
                                        <div className={s.displayInlineBlock}>
                                            <Loader
                                                type={"button"}
                                                label={formatMessage(messages.submitButton)}
                                                show={loading}
                                                buttonType={'submit'}
                                                className={cx(bt.btnPrimary)}
                                                disabled={submitting || loading}
                                                isSuffix={true}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
SafetySettingsForm = reduxForm({
    form: 'SafetySettingsForm',
    onSubmit: submit,
    validate
})(SafetySettingsForm);
const selector = formValueSelector('SafetySettingsForm')
const mapState = (state) => ({
    safetyGridImage1: selector(state, 'safetyGridImage1'),
    safetyGridImage2: selector(state, 'safetyGridImage2'),
    safetyGridImage3: selector(state, 'safetyGridImage3'),
    loading: state.loader.SafetySettingsForm,
    safetyGridImage3Loader: state.loader.safetyGridImage3Loader,
    safetyGridImage1Loader: state.loader.safetyGridImage1Loader,
    safetyGridImage2Loader: state.loader.safetyGridImage2Loader

})
const mapDispatch = {
}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(SafetySettingsForm)));
