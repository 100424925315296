import updateHomepageSettingsAbout from '../../../actions/siteadmin/updateHomepageSettingsAbout'
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {
    if (!values.aboutGridImage1 || !values.aboutGridImage2 || !values.aboutGridImage3 || !values.aboutGridImage4) {
        showToaster({ messageId: 'aboutGridImageError', toasterType: 'error' })
        return;
    }

    await dispatch(updateHomepageSettingsAbout(values))
}

export default submit;