const errorMessage = async (errorCode, requestContent) => {

    const message = {
        ogImageError: 'Please upload the OG image',
        addAdminRole: `Admin role has been ${requestContent ? 'updated' : 'added'} successfully!`,
        addAdminUser: `Admin user has been ${requestContent ? 'updated' : 'added'} successfully!`,
        addFare: `The fare has been ${requestContent ? 'updated' : 'added'} successfully!`,
        addContent: 'Please add content',
        addPromoCode: `Promo code has been ${requestContent ? 'updated' : 'added'} successfully!`,
        adminError: 'Oops! Your changes to admin details failed!',
        aboutGridImageError: 'Feature banner image is required.',
        addContentPage: 'Content page changes has been added successfully',
        addContentPageError: 'Creating Content Page Details failed',
        addImage: 'Please add image',
        addTripTimer: 'Please add trip timer tone',
        addDescription: 'Please add description',
        updatePricing1: `The fare has been ${requestContent === true ? 'activated' : 'deactivated'} successfully.`,
        addCategory: 'The category has been added successfully.',
        addCancelReason: `Cancel reason has been added successfully`,
        addLocationError: requestContent ? requestContent : 'Please select location',
        addLocationSuccess: 'The location has been added successfully.',
        baseCurrencyError: `You can't disable the base currency. If you want to disable this, please set the other currency as your base currency`,
        cancelBooking: 'Booking has been cancelled successfully.',
        commonError: requestContent ? requestContent : ' Oops,Something went wrong. Please try again.',
        updateAdminPassword: 'Admin account password has been updated successfully!',
        contentPageDelete: 'Content page details.Deleted successfully!',
        contentPageError: 'Content page details.Deleted failed!',
        commonStatus: 'Status has changed.',
        commonStatusError: 'Failed to change  status',
        cashPayoutError: 'Failed to update status for cash payout',
        catchError: `Something Went Wrong ${requestContent}`,
        categoryImage: 'Category icon is required.',
        categoryMarkerImage: 'Category map marker is required.',
        cancelReasonError: 'Cancel reason deletion failed',
        deletePromoCode: 'Promo code has been deleted successfully',
        deleteAdminUser: 'Admin user has been deleted successfully!',
        deleteCanReason: 'Cancel reason has been deleted successfully.',
        deleteDriver: 'The driver has been deleted successfully.',
        deleteRider: 'The rider has been deleted successfully.',
        deleteCategory: 'The Category has been deleted.',
        deleteLocation: 'The location has been deleted successfully.',
        deleteAdminRole: 'Admin role has been deleted successfully!',
        deleteFare: 'The fare has been deleted successfully.',
        deleteAdminUser: 'Admin user has been deleted successfully!',
        disableBaseCurrency: `You can't disable the base currency. If you want to disable this, please set the other currency as your base currency`,
        disableBaseCurrencyErr: 'You can’t be able to disable this currency since it has been used by the platform users',
        editContentPage: 'Content page changes has been updated',
        editContentPageError: 'Updating content Page details failed',
        existUrl: 'The page URL already exist!',
        getTripDetails: `Sorry, the service you're looking for is currently unavailable in the selected location. Please choose a different location and try again.`,
        updateCashPayout: 'Payout status has been updated for the Cash trip',
        updateBooking: 'Booking has been updated successfully.',
        updateCategory: 'Category has been updated!',
        updateDriver: 'Driver details has been updated successfully',
        updateRider: 'Rider details has been updated successfully',
        updateVehicle: 'Vehicle details has been updated successfully',
        updateVehicleError: `Can't Change the status as currently, they are on a trip, Please try again later`,
        updateCategoryImage: 'Category image has been uploaded!',
        updateCategoryStatus: 'The Category status has been updated.',
        updateHomePageSettings: 'Homepage settings changes has been updated successfully',
        updateMobileSettings: `The mobile app's settings have been updated!`,
        updateConfigSettings: 'The configuration settings have been updated!',
        updateSiteSettings: 'The site settings have been updated successfully!',
        updateStaticPage: 'Static content page changes has been updated successfully',
        uploadInsurance: 'Insurance image has been uploaded!',
        updatePreNotification: 'Changes has been updated successfully!',
        uploadLicenseImage: 'License images has been uploaded!',
        uploadProfileImage: 'Profile image has been uploaded!',
        uploadRCbookImage: 'RC book image has been uploaded!',
        updateCancelReason: 'Cancel reason has been updated successfully',
        updateTempImages: `The image has been ${requestContent ? 'updated' : 'added'} successfully!`,
        setBaseCurrency: 'Base currency has been set successfully',
        limitError: 'Maximum upload size Exceeded!. Try with smallest size image',
        fileTypeError: 'You are trying to upload invalid image file. Please upload PNG, JPG & JPEG format image file.',
        updateFail: 'Updating failed',
        toneError: 'You are trying to upload invalid file. Please upload mp3 format file.',
        footerLogo: 'Footer logo is required.',
        tripEnable: 'Please enable any one of the field in Trip Request Window',
        sendNotification: 'Push notification has been sent.',
        emailExist: 'Oops! this email address is already exist.',
        isFareUsed: 'Sorry, unable to inactive. The chosen category is used on the manage fare. Please remove the fare and try again.',
        inActiveCategory: 'Sorry, unable to delete. The chosen category is used on the manage fare. Please remove the fare and try again.',
        activeBooking: 'Oops! it looks like the user has a booking and unable to delete them.',
        selectDate: 'Please select a date',
        noRecord: 'No record found',
        invalidNumber: 'Invalid phone number',
        loginError: 'Please login with your account and continue.',
        noChanges: 'Oops! No changes made',
        checkIfUserEmail: 'This email already registered',
        checkIfAdminEmail: 'This email already registered by admin',
        serviceUnAvailable: 'Sorry, our service unavailable in your location at the moment.',
        authorizedError: `You haven\'t authorized for this action.`,
        alreadyLogin: 'You are already logged in',
        updatePromoCode: 'Oops! Something went wrong! Unable to update the promo code. Please try again.',
        promoCodeExist: 'Oops! It looks like already this promo code is used. Please try again with different promo code name.',
        promoCodeError: 'Oops! something went wrong. Unable to delete the promo code',
        addPromoCodeError: 'Oops! Something went wrong! Unable to create a new promo code. Please try again.',
        checkBookingExist: `Sorry! You can't change these pricing details. This selected pricing has the bookings.`,
        alreadyExistWithId: 'Sorry, it looks like the fare is already exist for the chosen location and category.',
        updatePricing: 'Sorry, unable to create a fare for the chosen location and category.',
        userBan: 'This user is banned',
        isDriverActive: 'Oops! It seems the user is active as a Driver to take trips from the riders. Please inform them to go OFFLINE from their driver account to book a ride for them here.',
        inActiveLocation: 'Sorry, unable to inactive. The chosen location is used on the manage fare. Please remove the fare and try again.',
        deleteLocationError: 'Sorry, unable to delete. The chosen location is used on the manage fare. Please remove the fare and try again.',
        disableBaseCurrency: `Sorry, you can't disable the base currency. Please change the platform's base currency and try again.`,
        disableCurrency: 'Oops! Unable to disable this currency. Our users are using this currency.',
        enableCard: 'Please enable Credit/Debit payment to activate this payment method.',
        selectPayment: 'AtLeast one payment method option must be active.',
        validRoleId: 'Oops, it looks like the chosen role is not valid. Please try with different valid role.',
        isAdminUsing: 'Oops! Unable to delete the admin role due to some admin user using this role.',
        findAdminRole: 'Sorry, unable to find this admin role.',
        invalidAdmin: 'Invalid admin user',
        createUser: 'Oops, something went wrong. Unable to create an admin user.',
        checkUserExist: 'Oops, the provided email is already exits with the other user.',
        checkNumberExist: 'Oops, the provided phone number is already exits with the other user.',
        driverStatus: `It seems this respective user is currently on a trip. So that, you can't be able to change the status`,
        updateUser: 'Oops! Something went wrong, unable to update the user information.',
        previousSchedules: 'You have already scheduled a ride before 30 minutes',
        getActiveBooking: 'Oops! It looks like rider having active booking. Please cancel the ride and try again.',
        getActiveScheduleBooking: 'Oops! It looks like rider have scheduled a ride in 30 minutes. Please cancel the existing ride and try again.',
        isDriverActiveResult: 'Oops! It seems the user is active as a Driver to take trips from the users. Please inform them to go OFFLINE from their driver account to book a ride for them here.',
        noLocation: 'Invalid location.',
        noDriver: 'Sorry, driver profile not found',
        invalidDriverId: 'Sorry, driver is not available. Please try again.',
        invalidRequestTime: 'Oops! You are trying to book a restricted date and time. Please contact our support team.',
        invalidRequestDate: 'Oops! It looks like you are trying to book a past date and please try with the future dates.',
        updateCurrencyStatus: 'Currency status has changed',
        updateCurrencyError: 'You can’t be able to disable this currency since it has been used by the platform users',
        baseCurrencyFailed: 'Failed to set base currency',
        allowPaymentCurrency: 'Allowed payment currency success',
        allowPaymentCurrencyError: 'Failed to set allowed payment currency',
        updatePayout: 'Payout status has been updated successfully',
        updatePayoutError: 'Failed to updated status',
        updatePaymentGateway: 'Payment gateway status has been updated successfully.',
        payoutError: 'Failed to change currency status',
        updateLocation: 'The location has been updated successfully.',
        success: 'Success!',
        error: 'Error!',
        warning: 'Warning!',
        accountDeleted: 'Your account has been deleted successfully.'
    }

    return message[errorCode]

}
export default errorMessage;