import React from 'react';
import Home from './Home';
import Layout from '../../components/Layout';

async function action({ client, store }) {
  const title = store.getState().siteSettings.data.siteTitle
  const description = store.getState().siteSettings.data.metaDescription;

  return {
    title,
    description,
    // component: (
    //   <Layout>
    //     <Home />
    //   </Layout>
    // ),
    component: (
      <p style={{ fontSize: '40px', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        A new beginning...
      </p>
    )
  };
}

export default action;
