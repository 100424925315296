import gql from 'graphql-tag';
import {
    CATEGORY_UPDATE_SUCCESS,
    CATEGORY_UPDATE_START,
    CATEGORY_UPDATE_ERROR
} from '../../constants/index';
import showToaster from '../../helpers/showToaster';

export default function updateCategoryStatus(id, isActive, currentPage) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: CATEGORY_UPDATE_START
        });


        try {

            let query = gql`
            query getAllCategory($currentPage: Int, $searchList: String){
                getAllCategory(currentPage: $currentPage, searchList: $searchList){
                 count
                  categoryData{
                      id
                      categoryName
                      categoryImage
                      categoryMarkerImage
                      basePrice
                      minutePrice
                      unitPrice
                      riderFeeValue
                      driverFeeValue
                      isActive
                      currency
                      capacity
                      createdAt
                      updatedAt
                  }
                }
              }
            `


            let mutation = gql`
            mutation updateCategoryStatus($id: Int, $isActive: Boolean) {
                updateCategoryStatus(id: $id, isActive: $isActive) {
                  status
                  errorMessage
                }
              }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    isActive: isActive == '1' ? 1 : 0
                },
                refetchQueries: [{ query, variables: { currentPage, searchList: '' } }]
            });

            if (data && data.updateCategoryStatus && data.updateCategoryStatus.status == 200) {
                dispatch({
                    type: CATEGORY_UPDATE_SUCCESS
                });
                showToaster({ messageId: 'updateCategoryStatus', toasterType: 'success', })
            } else {
                dispatch({
                    type: CATEGORY_UPDATE_ERROR
                });
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateCategoryStatus?.errorMessage })
            }
        } catch (err) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
            dispatch({
                type: CATEGORY_UPDATE_ERROR
            });
        }

    }
};