import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import messages from '../../../locale/messages';
import validate from './validate';
import { api, homepageUploadDir } from '../../../config';

import defalutImage from '../../../../public/Icons/defalutImage.svg';

import s from './HomeSettingsForm.css';
import bt from '../../../components/commonStyle.css';


export class HomeSettingsForm extends Component {
    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <label className={bt.labelText} >{label}</label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={bt.formControlInput} />
                {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }
    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.formGroup}>
                    <div>
                        <label className={bt.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="3"
                            maxLength={250}
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }
    render() {
        const { handleSubmit, loading, submitting, homeSectionImage1, homeSectionImage2, homeSectionImage3, homeSectionImage4, homeSectionImage5, } = this.props;
        const { homeSectionImage6, homeSectionImage7, homeSectionImage8, homeSectionImage6Loader, homeSectionImage7Loader, homeSectionImage8Loader } = this.props;
        const { formatMessage } = this.props.intl;
        let homeImage6, homeImage7, homeImage8;
        homeImage6 = homeSectionImage6 ? api.apiEndpoint + homepageUploadDir + 'medium_' + homeSectionImage6 : defalutImage;
        homeImage7 = homeSectionImage7 ? api.apiEndpoint + homepageUploadDir + 'medium_' + homeSectionImage7 : defalutImage;
        homeImage8 = homeSectionImage8 ? api.apiEndpoint + homepageUploadDir + 'medium_' + homeSectionImage8 : defalutImage;

        return (
            <div className={cx('cardSection', s.widthInner, bt.space5, s.responsiveNoPadding, s.widthInnerTwo, 'bgBlackTwo')}>
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12} className={s.responsiveNoPadding}>
                        <Card className={s.card}>
                            <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h1 className='textAlignRightRTL'>{formatMessage(messages.homepageBanner)}</h1>
                                </Col>
                                <Row>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                            <ImageUploadComponent
                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                loaderName={'homeSectionImage6Loader'}
                                                postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                loader={homeSectionImage6Loader}
                                                fieldName={'homeSectionImage6'}
                                                formName={'HomeSettingsForm'}
                                                imageSrc={homeImage6}
                                                inputContainer={'.dzInputContainerHomeImage6'}
                                                inputContainerClass={'dzInputContainerHomeImage6'}
                                                label={formatMessage(messages.homeImage6)}
                                                defaultImage={!homeSectionImage6 ? true : false}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="homeSectionTitle1" type="text" component={this.renderField} label={formatMessage(messages.homeTitle)} />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="homeSectionContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.homeContent1)} />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field name="homeSectionButton1" type="text" component={this.renderField} label={formatMessage(messages.buttonLabel)} />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={12} sm={12} xs={12} className={cx(bt.space2, bt.spaceTop4)}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'homeSectionImage7Loader'}
                                                    postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                    loader={homeSectionImage7Loader}
                                                    fieldName={'homeSectionImage7'}
                                                    formName={'HomeSettingsForm'}
                                                    imageSrc={homeImage7}
                                                    inputContainer={'.dzInputContainerHomeImage7'}
                                                    inputContainerClass={'dzInputContainerHomeImage7'}
                                                    label={formatMessage(messages.homeImage7)}
                                                    defaultImage={!homeSectionImage7 ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12} className={cx(bt.space2, bt.spaceTop4)}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'homeSectionImage8Loader'}
                                                    postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                    loader={homeSectionImage8Loader}
                                                    fieldName={'homeSectionImage8'}
                                                    formName={'HomeSettingsForm'}
                                                    imageSrc={homeImage8}
                                                    inputContainer={'.dzInputContainerHomeImage8'}
                                                    inputContainerClass={'dzInputContainerHomeImage8'}
                                                    label={formatMessage(messages.homeImage8)}
                                                    defaultImage={!homeSectionImage8 ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                                    <Form.Group className={s.formGroup}>
                                        <div className={s.displayInlineBlock}>
                                            <Loader
                                                type={"button"}
                                                label={formatMessage(messages.submitButton)}
                                                show={loading}
                                                buttonType={'submit'}
                                                className={cx(bt.btnPrimary)}
                                                disabled={submitting || loading}
                                                isSuffix={true}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
HomeSettingsForm = reduxForm({
    form: 'HomeSettingsForm',
    onSubmit: submit,
    validate
})(HomeSettingsForm);
const selector = formValueSelector('HomeSettingsForm')
const mapState = (state) => ({
    homeSectionImage1: selector(state, 'homeSectionImage1'),
    homeSectionImage2: selector(state, 'homeSectionImage2'),
    homeSectionImage3: selector(state, 'homeSectionImage3'),
    homeSectionImage4: selector(state, 'homeSectionImage4'),
    homeSectionImage5: selector(state, 'homeSectionImage5'),
    homeSectionImage6: selector(state, 'homeSectionImage6'),
    homeSectionImage7: selector(state, 'homeSectionImage7'),
    homeSectionImage8: selector(state, 'homeSectionImage8'),
    loading: state.loader.HomeSettingsForm,
    homeSectionImage6Loader: state.loader.homeSectionImage6Loader,
    homeSectionImage7Loader: state.loader.homeSectionImage7Loader,
    homeSectionImage8Loader: state.loader.homeSectionImage8Loader
})
const mapDispatch = {

}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(HomeSettingsForm)));
