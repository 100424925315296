import gql from 'graphql-tag';
import {
  SET_PAYOUT_START,
  SET_PAYOUT_SUCCESS,
  SET_PAYOUT_ERROR,
  UPDATE_PAYMENT_STATUS_START,
  UPDATE_PAYMENT_STATUS_SUCCESS,
  UPDATE_PAYMENT_STATUS_ERROR
} from '../../../constants';
import showToaster from '../../../helpers/showToaster';
import getPaymentMethodsQuery from '../../../routes/site-admin/paymentGateway/getPaymentMethods.graphql';
import updatePaymentMutation from './updatePayment.graphql';

export function updatePayoutStatus(id, isBanStatus) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SET_PAYOUT_START,
    });

    try {
      let getPayoutList = gql`
      query getPayoutList($currentPage: Int, $searchList: String){
        getPayoutList(currentPage: $currentPage, searchList:$searchList){
          count
        bookingData{
          id
          tripStatus
          driverDetails{
            firstName
            userData{
              email
            }
          }
          totalFare
          isPayoutPaid
          isBanStatus
          paymentType
        }
      }
    }
      `

      let mutation = gql`
        mutation updatePayout ($id: Int!, $isBanStatus: Boolean!){
          updatePayout(id: $id, isBanStatus: $isBanStatus){
              status
              errorMessage
            }
        }`;

      const { data } = await client.mutate({
        mutation,
        variables: { id, isBanStatus },
        refetchQueries: [{ query: getPayoutList, variables: { currentPage: '1', searchList: '' } }]
      });

      if (data.updatePayout.status === 200) {

        dispatch({
          type: SET_PAYOUT_SUCCESS,
        });
        showToaster({ messageId: 'updatePayout', toasterType: 'success' })
      } else {

        dispatch({
          type: SET_PAYOUT_ERROR,
        });
        showToaster({ messageId: 'updatePayoutError', toasterType: 'error' })

        return false;
      }
    } catch (error) {

      dispatch({
        type: SET_PAYOUT_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'payoutError', toasterType: 'error' })
      return false;
    }
    return true;
  };
}

export function updatePaymentMethod(id, status) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: UPDATE_PAYMENT_STATUS_START,
    });
    try {

      const { data } = await client.mutate({
        mutation: updatePaymentMutation,
        variables: { id, status },
        refetchQueries: [{ query: getPaymentMethodsQuery }]
      });
      if (data.updatePayment.status === 200) {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_SUCCESS,
        });
        showToaster({ messageId: 'updatePaymentGateway', toasterType: 'success' })
      } else {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_ERROR,
          payload: {
            error: data.updatePayment && data.updatePayment.errorMessage
          }
        });
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updatePayment?.errorMessage })
        return false;
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_STATUS_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'updatePayoutError', toasterType: 'error' })
      return false;
    }
    return true;
  };
}
