import { updateMobileSettings } from '../../../actions/siteadmin/updateMobileSettings'
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {

    let isTripEnabled = values.distance === '0' && values.duration === '0' && values.estimatedPrice === '0' && values.pickupLocation === '0' && values.destinationLocation === '0';

    if (isTripEnabled) {
        showToaster({ messageId: 'tripEnable', toasterType: 'error' })
        return;
    }

    if (values.isTripTimerToneEnable == '1' && !values.tripTimeTone) {
        showToaster({ messageId: 'addTripTimer', toasterType: 'error' })
        return;
    }

    await dispatch(
        updateMobileSettings(
            values.appForceUpdate,
            values.riderAndroidVersion,
            values.riderIosVersion,
            values.driverAndroidVersion,
            values.driverIosVersion,
            values.multipleStopsWaitingTime,
            values.preferredDistanceType,
            values.distance,
            values.duration,
            values.estimatedPrice,
            values.pickupLocation,
            values.destinationLocation,
            values.sleepDriverAndroid,
            values.sleepDriverios,
            values.contactPhoneNumber,
            values.contactEmail,
            values.skype,
            values.startRideRadius,
            values.tripTimeTone,
            values.isTripTimerToneEnable,
            values.openAppOnTrip,
            values.tripToneFile,
            values.maxWaitingTime,
            values.notificationInterval
        )
    )
}

export default submit;