import gql from 'graphql-tag';
import {
    CANCEL_REASON_DELETE_SUCCESS,
    CANCEL_REASON_DELETE_START,
    CANCEL_REASON_DELETE_ERROR
} from '../../constants/index';
import showToaster from '../../helpers/showToaster';

export default function removeCancelReason(id, currentPage) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: CANCEL_REASON_DELETE_START
        });

        try {

            let query = gql`
            query getAllCancelReason ($searchList: String $currentPage: Int) {
                getAllCancelReason(searchList: $searchList currentPage: $currentPage) {
                  count
                  result{
                    id
                    reason
                    userType
                    isActive
                  }
                  status
                }
              }
            `;

            let mutation = gql`
            mutation($id: Int) {
                removeCancelReason(id: $id) {
                    status
                }
            }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                },
                refetchQueries: [{ query, variables: { currentPage, searchList: '' } }]
            });

            if (data && data.removeCancelReason && data.removeCancelReason.status == "200") {
                dispatch({
                    type: CANCEL_REASON_DELETE_SUCCESS
                });
                showToaster({ messageId: 'deleteCanReason', toasterType: 'success' })
            } else {
                dispatch({
                    type: CANCEL_REASON_DELETE_ERROR
                });
                showToaster({ messageId: 'cancelReasonError', toasterType: 'error' })
            }
        } catch (err) {
            dispatch({
                type: CANCEL_REASON_DELETE_ERROR
            });
            showToaster({ messageId: 'cancelReasonError', toasterType: 'error' })
        }

    }
};