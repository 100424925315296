import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Nav, NavDropdown } from 'react-bootstrap';
import Link from '../Link';
import messages from '../../locale/messages';
import cx from 'classnames';
import { adminLogout } from '../../actions/siteadmin/logout';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
// Helpers
import history from '../../history';
import s from './AdminHeader.css';

//Images 
import LogOutIcon from '../../../public/Icons/logout.svg';
import MainSiteIcon from '../../../public/Icons/mainSite.svg';
import HeaderModal from '../HeaderModal/HeaderModal';
import { openHeaderModal } from '../../actions/siteadmin/modalActions';
import { formatLocale } from '../../helpers/formatLocale';
import CheckUserStatusQuery from './getCheckUserStatus.graphql';
import LanguageIcon from '../../../public/Icons/languageIcon.svg';
import { choseToTheme } from '../../actions/currency/getCurrencyRates';
//images
import selectedLightIcon from '../../../public/Icons/lightModeOn.svg';
import selectedDarkIcon from '../../../public/Icons/darkModeOn.svg';
import dropDownLightIcon from '../../../public/Icons/lightModeOff.svg';
import dropDownDarkIcon from '../../../public/Icons/darkModeOff.svg';
import welcomeIcon from '../../../public/Icons/welcomeIcon.png';
import LogOutIconMobile from '../../../public/Icons/logoutMobile.svg';



class AdminHeader extends React.Component {
  static defaultProps = {
    checkLoginUserExist: {
      userExistloading: true,
      getAdminUserExists: {
        userExistStatus: null,
      },
    }
  };

  navigation(URL) {
    if (URL) {
      history.push(URL);
    }
  }

  handleChange(e) {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  }


  render() {
    const { openHeaderModal, currentLocale, adminLogout, selectedTheme, choseToTheme, openMenu } = this.props;
    const { checkLoginUserExist: { userExistloading, getAdminUserExists } } = this.props;
    const { socket, userId } = this.props;
    if (socket && userId) {
      socket.on(`webAdminUserLogout-${userId}`, (data) => {
        if (typeof window !== "undefined") window.location.assign('/adminUserLogout');
      });
    }

    if (!userExistloading && getAdminUserExists) {
      if (getAdminUserExists.userExistStatus) {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
          adminLogout();
          window.location.reload();
        }
      }
    }

    return (
      <div className={cx(s.adminHeaderContainer, 'bgBlack', 'mainMenu')}>
        <div className={cx(s.adminHeaderBurgerButton, "d-block", "d-lg-none")} onClick={() => openMenu()}>
          <span className={s.iconBar}></span>
          <span className={s.iconBar}></span>
          <span className={s.iconBar}></span>
        </div>
        <div className={cx(s.adminHeaderWelcomeBox, 'adminHeaderWelcomeBoxRtl')}>
          <img src={welcomeIcon} className={s.welcomeIconCss} />
          <FormattedMessage {...messages.siteAdmin} />
        </div>
        <div className={cx(s.adminHeaderMenuItems, 'adminHeaderMenuItemsRtl', 'd-none d-lg-block d-xl-block')}>
          <Nav>
            <HeaderModal />
            <NavDropdown title={
              <>
                {
                  (selectedTheme && selectedTheme === "light") &&
                  <span className={s.selectedThemeColor}> <img src={selectedLightIcon} alt='' /> <FormattedMessage {...messages.lightMode} /></span>
                }
                {
                  (selectedTheme && selectedTheme === "dark") &&
                  <span className={s.selectedThemeColorDark}> <img src={selectedDarkIcon} alt='' /> <FormattedMessage {...messages.darkMode} /></span>
                }
              </>
            }

              noCaret id="basic-nav-dropdown1" className={cx(s.nonBreakPointScreen, 'adminThemeHeader', "adminLanguageMenu")}>
              <NavDropdown.Item to="#" onClick={() => { choseToTheme('light') }} className={cx(s.themeIconDropDownLink, s.borderFirst)}>
                <div className={cx({ [s.selectedThemeColor]: selectedTheme === "light" }, s.themeIconSec)}>
                  {
                    (selectedTheme && selectedTheme === "light") ?
                      <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> : <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                  }
                  <FormattedMessage {...messages.lightMode} />
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item to="#" onClick={() => { choseToTheme('dark') }} className={s.themeIconDropDownLink}>
                <div className={cx({ [s.selectedThemeColorDark]: selectedTheme === "dark" }, s.themeIconSec)}>
                  {
                    (selectedTheme && selectedTheme === "dark") ?
                      <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> : <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                  }
                  <FormattedMessage {...messages.darkMode} />
                </div>
              </NavDropdown.Item>
            </NavDropdown>
            <Link noLink
              onClick={(e) => this.handleChange(e)}
              className={cx(s.siteColor, s.cursurPointer, 'textWhite', "siteColorRtl")}>
              <span className={cx(s.displayInlineBlock, s.vtrTop, s.iconWidth, s.languageIcon, 'svgImg')}>
                <img src={LanguageIcon} className={cx(s.sideMenuIcon)} />
              </span>
              <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, 'iconTextPaddingRTL')}>
                {formatLocale(currentLocale)}
              </span>
            </Link>
            <Link to={'/'} onClick={() => this.openClose()} className={cx(s.siteColor, 'textWhite', "siteColorRtl")}>
              <span className={cx(s.displayInlineBlock, s.vtrTop, s.iconWidth, 'svgImg')}>
                <img src={MainSiteIcon} className={cx(s.sideMenuIcon)} />
              </span>
              <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, 'iconTextPaddingRTL')}>
                <FormattedMessage {...messages.goToMainSite} />
              </span>
            </Link>
            <Link to={'/login'} onClick={() => adminLogout()} className={cx(s.siteColor, 'textWhite', "siteColorRtl")}>
              <span className={cx(s.displayInlineBlock, s.vtrTop, s.iconWidth, 'svgImg')}>
                <img src={LogOutIcon} className={cx(s.sideMenuIcon)} />
              </span>
              <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding, s.hideLayoutText, 'iconTextPaddingRTL')}>
                <FormattedMessage {...messages.logout} />
              </span>
            </Link>
          </Nav>
        </div>
        <div className={cx(s.vtrTop, s.iconWidth, "d-block", "d-lg-none")} onClick={() => adminLogout()}>
          <img src={LogOutIconMobile} className={cx(s.sideMenuIcon)} />
        </div>
      </div>
    )
  }
}

const mapState = state => ({
  currentLocale: state.intl.locale,
  userId: state.adminPrevileges.privileges && state.adminPrevileges.privileges.id,
  selectedTheme: state.currency.theme
});
const mapDispatch = {
  openHeaderModal,
  adminLogout,
  choseToTheme

};

// export default withStyles(s)(connect(mapState, mapDispatch)(AdminHeader));
export default compose(injectIntl,
  withStyles(s),
  graphql(CheckUserStatusQuery, {
    name: 'checkLoginUserExist',
    options: {
      ssr: false,
      pollInterval: 60000, // 1 minute
    },
  }),
  (connect(mapState, mapDispatch))
)(AdminHeader);