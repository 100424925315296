import updateSiteSettings from '../../../actions/siteadmin/updateSiteSettings';
import showToaster from '../../../helpers/showToaster';


async function submit(values, dispatch) {

    if (!values?.ogImage) {
        return showToaster({ messageId: 'ogImageError', toasterType: 'error' })
    }

    await dispatch(
        updateSiteSettings(
            values.siteName,
            values.siteTitle,
            values.metaDescription,
            values.facebookLink,
            values.twitterLink,
            values.instagramLink,
            values.metaKeyword,
            values.homeLogo,
            values.youtubeLink,
            values.favicon,
            values.maxUploadSize,
            values.ogImage
        )
    )
}

export default submit;