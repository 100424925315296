import gql from 'graphql-tag';
import {
  ADD_UPDATE_PRICING_START,
  ADD_UPDATE_PRICING_SUCCESS,
  ADD_UPDATE_PRICING_ERROR
} from '../../../constants';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import showToaster from '../../../helpers/showToaster';

const mutation = gql`
mutation($id: Int, $categoryId: Int!, $locationId: Int!, $unitPrice: Float, $minutePrice: Float, $basePrice: Float,
  $currency: String!, $riderFeeType: String, $riderFeeValue: Float, $driverFeeType: String, $driverFeeValue: Float, 
  $isActive: Boolean, $isSurgePrice: Boolean, $baseFare: Float, $surgePricing: String, $waitingPrice: Float) {
  addUpdatePricing(id: $id, categoryId: $categoryId, locationId: $locationId, unitPrice: $unitPrice,
      minutePrice: $minutePrice, basePrice: $basePrice, currency: $currency, riderFeeType: $riderFeeType,
      riderFeeValue:$riderFeeValue, driverFeeType: $driverFeeType, driverFeeValue: $driverFeeValue, 
      isActive: $isActive, isSurgePrice: $isSurgePrice, baseFare: $baseFare, surgePricing: $surgePricing, waitingPrice: $waitingPrice) {
      id
      status
      errorMessage    
  }
}`;

export function addUpdatePricing(
  id,
  locationId,
  categoryId,
  currency,
  basePrice,
  unitPrice,
  minutePrice,
  riderFeeType,
  riderFeeValue,
  driverFeeType,
  driverFeeValue,
  isActive,
  isSurgePrice,
  baseFare,
  surgePricing,
  waitingPrice
) {
  return async (dispatch, getState, { client }) => {
    try {
      dispatch({
        type: ADD_UPDATE_PRICING_START,
        payload: {
          pricingLoading: true
        }
      });

      dispatch(setLoaderStart('PricingForm'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          locationId,
          categoryId,
          currency,
          basePrice,
          unitPrice,
          minutePrice,
          riderFeeType,
          riderFeeValue,
          driverFeeType,
          driverFeeValue,
          isActive,
          isSurgePrice,
          baseFare,
          surgePricing,
          waitingPrice
        }
      });

      if (data && data.addUpdatePricing && data.addUpdatePricing.status === 200) {
        history.push('/siteadmin/pricing/list');
        showToaster({ messageId: 'addFare', toasterType: 'success', requestContent: id })
        await dispatch({
          type: ADD_UPDATE_PRICING_SUCCESS,
          payload: {
            pricingLoading: false
          }
        });

        dispatch(setLoaderComplete('PricingForm'));

        return await {
          status: 200
        };
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.addUpdatePricing?.errorMessage })
        await dispatch({
          type: ADD_UPDATE_PRICING_ERROR,
          payload: {
            pricingLoading: false,
            error: data && data.addUpdatePricing && data.addUpdatePricing.errorMessage
          }
        });

        dispatch(setLoaderComplete('PricingForm'));

        return await {
          status: 400
        };
      }
    } catch (error) {
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
      await dispatch({
        type: ADD_UPDATE_PRICING_ERROR,
        payload: {
          pricingLoading: false,
          error: "Something went wrong! " + error
        }
      });

      dispatch(setLoaderComplete('PricingForm'));

      return await {
        status: 400
      };
    }
  }
}