import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import submit from './submit';
import validate from './validate';
import { injectIntl, FormattedMessage } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import NavDropdown from 'react-bootstrap/NavDropdown';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';

import Loader from '../../Common/Loader';
import Link from '../../Link/Link';
import HeaderModal from '../../HeaderModal/HeaderModal';

import messages from '../../../locale/messages';
import { api, logoUploadDir } from '../../../config';
import { openHeaderModal } from '../../../actions/siteadmin/modalActions';
import { formatLocale } from '../../../helpers/formatLocale';
import { choseToTheme } from '../../../actions/currency/getCurrencyRates';

import globalIcon from '../../../../public/Icons/Language-icon.svg';
import gotoIcon from '../../../../public/Icons/gotoSite.svg';
import adminLoginImage from '../../../../public/static/loginBigImage.svg';
import arrowLeft from '../../../../public/Icons/loginArrow.svg';
import arrowDark from '../../../../public/Icons/loginArrowDark.svg';
import selectedLightIcon from '../../../../public/Icons/lightModeOn.svg';
import selectedDarkIcon from '../../../../public/Icons/darkModeOn.svg';
import dropDownLightIcon from '../../../../public/Icons/lightModeOff.svg';
import dropDownDarkIcon from '../../../../public/Icons/darkModeOff.svg';

import s from './AdminLoginForm.css';
import bt from '../../../components/commonStyle.css';

class AdminLoginForm extends Component {

  static defaultProps = {
    loading: false,
    siteName: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true
    }
  }

  componentDidMount() {
    const isBrowser = typeof window !== 'undefined';
    isBrowser && this.setState({
      isDisabled: false
    });
  }

  renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <label className={bt.labelText} >{label}</label>
        <Form.Control {...input} placeholder={placeholder} type={type} className={bt.formControlInput} />
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </Form.Group>
    )
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, loading, siteName, logo, openHeaderModal, currentLocale, selectedTheme, choseToTheme } = this.props;
    const { formatMessage } = this.props.intl;
    const { isDisabled } = this.state

    return (
      <div className={s.loginMainBg}>
        <div className={s.loginBg} style={{ backgroundImage: `url(${adminLoginImage})` }}></div>
        <div>
          <div className={cx(s.header, 'loginHeaderRTL', 'loginHeader')}>
            <HeaderModal />
            <NavDropdown title={
              <>
                {
                  (selectedTheme && selectedTheme === "light") &&
                  <span className={cx(s.selectedThemeColor, s.languageText)}> <img src={selectedLightIcon} alt='' /> <FormattedMessage {...messages.lightMode} /></span>
                }
                {
                  (selectedTheme && selectedTheme === "dark") &&
                  <span className={cx(s.selectedThemeColorDark, s.languageText)}> <img src={selectedDarkIcon} alt='' /> <FormattedMessage {...messages.darkMode} /></span>
                }
              </>
            }
              noCaret id="basic-nav-dropdown1" className={cx(s.nonBreakPointScreen, 'adminThemeHeader')}>
              <NavDropdown.Item to="#" onClick={() => { choseToTheme('light') }} className={cx(s.themeIconDropDownLink, s.borderFirst, s.languageText)}>
                <div className={cx({ [s.selectedThemeColor]: selectedTheme === "light" }, s.themeIconSec)}>
                  {
                    (selectedTheme && selectedTheme === "light") ?
                      <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> : <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                  }
                  <FormattedMessage {...messages.lightMode} />
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item to="#" onClick={() => { choseToTheme('dark') }} className={cx(s.themeIconDropDownLink, s.languageText)}>
                <div className={cx({ [s.selectedThemeColorDark]: selectedTheme === "dark" }, s.themeIconSec)}>
                  {
                    (selectedTheme && selectedTheme === "dark") ?
                      <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> : <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                  }
                  <FormattedMessage {...messages.darkMode} />
                </div>
              </NavDropdown.Item>
            </NavDropdown>
            <Link noLink
              onClick={() => openHeaderModal('languageModal')}
              className={cx(s.flexLanguage, 'svgImg')}>

              <img src={globalIcon} className={s.globalIconCss} />

              <span className={cx(s.languageText, 'languageTextRTL', 'textWhite')}>
                {formatLocale(currentLocale)}
              </span>
            </Link>
            <Link to={'/'} className={cx(s.flexLanguage, 'svgImg')}> <img src={gotoIcon} className={s.globalIconCss} /> <span className={cx(s.languageText, 'languageTextRTL', 'textWhite', s.logoutText)}><FormattedMessage {...messages.goToMainSite} /></span></Link>
          </div>
          <div className={cx(s.container, 'loginInput')}>
            <Card className={cx(s.card, 'cardRTL', 'bgBlack')}>
              <img
                src={api.apiEndpoint + logoUploadDir + logo}
                width={128}
                height={'auto'}
                alt={siteName}
              />
              <div className={s.titleCss}><FormattedMessage {...messages.siteAdmin} /></div>
              <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                {error && <span className={bt.errorMessage}>{error}</span>}
                <Field
                  name="email"
                  type="text"
                  component={this.renderField}
                  label={formatMessage(messages.email)}
                  placeholder={formatMessage(messages.emailaddress)}
                  labelClass={bt.labelText}
                  fieldClass={cx(bt.formControlInput)}
                />
                <Field
                  name="password"
                  type="password"
                  component={this.renderField}
                  label={formatMessage(messages.password)}
                  placeholder={formatMessage(messages.password)}
                  labelClass={bt.labelText}
                  fieldClass={cx(bt.formControlInput)}
                />
                <Form.Group className={cx(s.loginButton, 'loadingBtnRTL')}>
                  <div>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.login)}
                      show={loading}
                      buttonType={'submit'}
                      className={cx(bt.btnPrimary)}
                      disabled={submitting || loading || isDisabled}
                      isSuffix={true}
                      image={selectedTheme === 'light' ? arrowLeft : arrowDark}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

AdminLoginForm = reduxForm({
  form: 'AdminLoginForm', // a unique name for this form
  validate,
  onSubmit: submit
})(AdminLoginForm);

const mapState = (state) => ({
  loading: state?.loader?.AdminLogin,
  siteName: state?.siteSettings?.data?.siteName,
  logo: state?.siteSettings?.data?.homeLogo,
  logoHeight: state?.siteSettings?.data?.logoHeight,
  logoWidth: state?.siteSettings?.data?.logoWidth,
  currentLocale: state?.intl?.locale,
  selectedTheme: state?.currency?.theme
});

const mapDispatch = {
  openHeaderModal,
  choseToTheme
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AdminLoginForm)));

