import gql from 'graphql-tag';
import showToaster from '../../../helpers/showToaster';

import query from '../../../routes/site-admin/viewBooking/viewBookingDetails.graphql';

const mutation = gql`
mutation updateBooking($bookingId: Int!, $isSchedule: Boolean!, $scheduleFrom: Int, $bookingLocations: [UpdateBookingLocationsInputObjectType]) {
  updateBooking(bookingId: $bookingId, isSchedule: $isSchedule, scheduleFrom: $scheduleFrom, bookingLocations: $bookingLocations) {
    status
    errorMessage
  }
}
`;

export default function updateBooking(variables) {
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation,
                variables,
                refetchQueries: [{ query, variables: { id: variables.bookingId } }]
            });

            if (data && data.updateBooking && data.updateBooking.status === 200) {
                showToaster({ messageId: 'updateBooking', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateBooking?.errorMessage })
            }
            return true;
        }
        catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            return false;
        }
    };
}