import updateHomepageSettingsFooter from '../../../actions/siteadmin/updateHomepageSettingsFooter'
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {
    if (!values.footerLogo1) {
        showToaster({ messageId: 'footerLogo', toasterType: 'error' })
        return;
    }
    await dispatch(updateHomepageSettingsFooter(values))
}

export default submit;