import gql from 'graphql-tag';
import showToaster from '../../../helpers/showToaster';
import { removeStaticBannerImage } from './updatePrecautionNotification';

const mutation = gql`
    mutation updatePrecautionNotificationImage(
        $id:Int!,
        $imageName:String) {
            updatePrecautionNotificationImage(
                id:$id,
                imageName:$imageName) {
                    status
                    errorMessage
            }
    }
`;

export function updatePrecautionNotificationImage({ id, imageName }, oldImage) {

    return async (dispatch, getState, { client }) => {
        try {
            if (oldImage) await removeStaticBannerImage(oldImage);

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    imageName
                }
            });

            if (data.updatePrecautionNotificationImage && data.updatePrecautionNotificationImage.status == 200) {
                showToaster({ messageId: 'updateStaticPage', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'updateFail', toasterType: 'error' })
            }
        }
        catch (error) {
            console.log(error)
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
        }
    };
}