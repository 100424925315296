import gql from 'graphql-tag';
import {
    ADD_CANCEL_REASON_START,
    ADD_CANCEL_REASON_SUCCESS,
    ADD_CANCEL_REASON_ERROR
} from '../../constants/index';
import history from '../../history';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';
import showToaster from '../../helpers/showToaster';

let addMutation = gql`
mutation(
    $userType: Int, 
    $reason: String, 
    $isActive: Boolean) {
    addCancelReason(
        userType: $userType, 
        reason: $reason, 
        isActive: $isActive
        ) {
        status
    }
}
`

let updateMutation = gql`
mutation(
    $id: Int, 
    $userType: Int, 
    $reason: String, 
    $isActive: Boolean
    ) {
    updateCancelReason(
        id: $id, 
        userType: $userType, 
        reason: $reason, 
        isActive: $isActive
        ) {
        status
    }
}
`

export function addCancelReason(values) {

    return async (dispatch, getState, { client }) => {

        values.isActive = Number(values.isActive);

        let status;
        try {

            dispatch({
                type: ADD_CANCEL_REASON_START
            })

            dispatch(setLoaderStart('AddCancellation'));

            let mutation = values && values.id ? updateMutation : addMutation

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id: values && values.id,
                    reason: values && values.reason,
                    userType: values && values.userType,
                    isActive: values && values.isActive
                }
            })


            if (data && values.id) {
                status = data.updateCancelReason && data.updateCancelReason.status;
            } else if (data) {
                status = data.addCancelReason && data.addCancelReason.status;
            }

            if (status && status == '200') {
                history.push('/siteadmin/cancel-reasons');

                if (values && values.id) {
                    showToaster({ messageId: 'updateCancelReason', toasterType: 'success' })
                } else {
                    showToaster({ messageId: 'addCancelReason', toasterType: 'success' })
                }
                dispatch(setLoaderComplete('AddCancellation'));

                await dispatch({
                    type: ADD_CANCEL_REASON_SUCCESS,
                });

            } else {
                dispatch(setLoaderComplete('AddCancellation'));
                showToaster({ messageId: 'commonError', toasterType: 'error' })
                await dispatch({
                    type: ADD_CANCEL_REASON_ERROR,
                });
            }

        } catch (err) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
            dispatch({ type: ADD_CANCEL_REASON_ERROR })
        }
    }
}