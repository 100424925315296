import gql from 'graphql-tag';
import showToaster from '../../helpers/showToaster';
import {
	SITE_SETTINGS_UPLOAD_START,
	SITE_SETTINGS_UPLOAD_ERROR,
	SITE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import { setLoaderStart, setLoaderComplete } from '../loader/loader'

export default function updateSiteSettings(
	siteName, siteTitle, metaDescription, facebookLink, twitterLink, instagramLink,
	metaKeyword, homeLogo, youtubeLink, favicon, maxUploadSize, ogImage
) {

	return async (dispatch, getState, { client }) => {

		dispatch({
			type: SITE_SETTINGS_UPLOAD_START
		})

		try {

			const mutation = gql`
            mutation updateSiteSettings(
                $siteName: String,
                $siteTitle: String,
                $metaDescription: String, 
                $facebookLink: String,
                $twitterLink: String,
                $instagramLink: String,
                $metaKeyword: String,
                $homeLogo: String,
                $youtubeLink: String,
				$favicon: String,
				$maxUploadSize: String,
				$ogImage: String,
                ) {
                    updateSiteSettings(
                    siteName: $siteName
                    siteTitle: $siteTitle
                    metaDescription: $metaDescription
                    facebookLink: $facebookLink
                    twitterLink: $twitterLink
                    instagramLink: $instagramLink
                    metaKeyword: $metaKeyword
                    homeLogo: $homeLogo
                    youtubeLink: $youtubeLink
					favicon: $favicon
					maxUploadSize: $maxUploadSize,
					ogImage: $ogImage
                    ){
                    status
                  }
                }
            `
			dispatch(setLoaderStart('SiteSettings'))
			const { data } = await client.mutate({
				mutation,
				variables: {
					siteName,
					siteTitle,
					metaDescription,
					facebookLink,
					twitterLink,
					instagramLink,
					metaKeyword,
					homeLogo,
					youtubeLink,
					favicon,
					maxUploadSize,
					ogImage
				}
			})

			dispatch(setLoaderComplete('SiteSettings'))
			if (data && data.updateSiteSettings && data.updateSiteSettings.status == 200) {

				dispatch({
					type: SITE_SETTINGS_UPLOAD_SUCCESS
				})
				showToaster({ messageId: 'updateSiteSettings', toasterType: 'success' })
			} else {
				dispatch({
					type: SITE_SETTINGS_UPLOAD_ERROR
				})
				showToaster({ messageId: 'commonError', toasterType: 'error' })
			}
		} catch (err) {
			dispatch({
				type: SITE_SETTINGS_UPLOAD_ERROR
			})
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
		}

	}
}
