import { addPromoCode } from '../../../../actions/siteadmin/PromoCode/addPromoCode';
import showToaster from '../../../../helpers/showToaster';

async function submit(values, dispatch) {
        if (!values || !values.image) {
                showToaster({ messageId: 'addImage', toasterType: 'error' })
                return;
        }
        await dispatch(addPromoCode(values));
}

export default submit;