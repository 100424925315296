import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';

import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';
import Loader from '../../Common/Loader';

import messages from '../../../locale/messages';
import validate from './validate';
import { api, homepageUploadDir } from '../../../config';

import defaultIcon from '../../../../public/Icons/defalutImage.svg';

import s from './AboutSettingsForm.css';
import bt from '../../../components/commonStyle.css';

export class AboutSettingsForm extends Component {
	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<label className={bt.labelText} >{label}</label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={bt.formControlInput} />
				{touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}
	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<FormGroup className={s.formGroup}>
					<div>
						<label className={bt.labelText} >{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={className}
							placeholder={label}
							as="textarea"
							rows="3"
						>
							{children}
						</FormControl>
						{touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}
	render() {
		const { handleSubmit, aboutGridImage1, aboutGridImage2, loading, submitting, aboutGridImage3, aboutGridImage4 } = this.props;
		const { aboutGridImage2Loader, aboutGridImage1Loader, aboutGridImage3Loader, aboutGridImage4Loader } = this.props;
		const { formatMessage } = this.props.intl;
		let gridImage2, gridImage1, gridImage3, gridImage4;
		gridImage2 = aboutGridImage2 ? api.apiEndpoint + homepageUploadDir + aboutGridImage2 : defaultIcon;
		gridImage1 = aboutGridImage1 ? api.apiEndpoint + homepageUploadDir + aboutGridImage1 : defaultIcon;
		gridImage3 = aboutGridImage3 ? api.apiEndpoint + homepageUploadDir + aboutGridImage3 : defaultIcon;
		gridImage4 = aboutGridImage4 ? api.apiEndpoint + homepageUploadDir + aboutGridImage4 : defaultIcon;

		return (
			<div className={cx('cardSection', s.widthInner, bt.space5, s.responsiveNoPadding, s.widthInnerTwo, 'bgBlackTwo')}>
				<Row>
					<Col md={12} lg={12} sm={12} xs={12} className={s.responsiveNoPadding}>
						<Card className={s.card}>
							<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
								<Col lg={12} md={12} sm={12} xs={12}>
									<h1 className='textAlignRightRTL'>{formatMessage(messages.aboutSectionSettings)}</h1>
								</Col>
								<Row>
									<Col lg={6} md={12} sm={12} xs={12} className={bt.space2}>
										<Form.Group className={s.formGroup}>
											<div className={cx(s.profileImgSection, s.profileImgWidth)}>
												<ImageUploadComponent
													subText={formatMessage(messages.maximumUploadSizeLabel)}
													defaultMessage={formatMessage(messages.chooseFile)}
													loaderName={'aboutGridImage2Loader'}
													postUrl={api.apiEndpoint + '/uploadHomepageImage'}
													loader={aboutGridImage2Loader}
													fieldName={'aboutGridImage2'}
													formName={'AboutSettingsForm'}
													imageSrc={gridImage2}
													inputContainer={'.dzInputContainerAboutImage2'}
													inputContainerClass={'dzInputContainerAboutImage2'}
													label={formatMessage(messages.aboutImage1)}
													defaultImage={!aboutGridImage2 ? true : false}
												/>
											</div>
										</Form.Group>
									</Col>
									<Col lg={6} md={12} sm={12} xs={12}>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="aboutGridTitle1" type="text" component={this.renderField} label={formatMessage(messages.featureTitle1)} />
											</div>
										</Form.Group>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="aboutGridContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.featureDesc1)} />
											</div>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col lg={6} md={12} sm={12} xs={12} className={bt.space2}>
										<Form.Group className={s.formGroup}>
											<div className={cx(s.profileImgSection, s.profileImgWidth)}>
												<ImageUploadComponent
													subText={formatMessage(messages.maximumUploadSizeLabel)}
													defaultMessage={formatMessage(messages.chooseFile)}
													loaderName={'aboutGridImage1Loader'}
													postUrl={api.apiEndpoint + '/uploadHomepageImage'}
													loader={aboutGridImage1Loader}
													fieldName={'aboutGridImage1'}
													formName={'AboutSettingsForm'}
													imageSrc={gridImage1}
													inputContainer={'.dzInputContainerAboutImage1'}
													inputContainerClass={'dzInputContainerAboutImage1'}
													label={formatMessage(messages.aboutImage2)}
													defaultImage={!aboutGridImage1 ? true : false}
												/>
											</div>
										</Form.Group>
									</Col>
									<Col lg={6} md={12} sm={12} xs={12}>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="aboutGridTitle2" type="text" component={this.renderField} label={formatMessage(messages.featureTitle2)} />
											</div>
										</Form.Group>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="aboutGridContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.featureDesc2)} />
											</div>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col lg={6} md={12} sm={12} xs={12} className={bt.space2}>
										<Form.Group className={s.formGroup}>
											<div className={cx(s.profileImgSection, s.profileImgWidth)}>
												<ImageUploadComponent
													subText={formatMessage(messages.maximumUploadSizeLabel)}
													defaultMessage={formatMessage(messages.chooseFile)}
													loaderName={'aboutGridImage3Loader'}
													postUrl={api.apiEndpoint + '/uploadHomepageImage'}
													loader={aboutGridImage3Loader}
													fieldName={'aboutGridImage3'}
													formName={'AboutSettingsForm'}
													imageSrc={gridImage3}
													inputContainer={'.dzInputContainerAboutImage1'}
													inputContainerClass={'dzInputContainerAboutImage1'}
													label={formatMessage(messages.aboutImage3)}
													defaultImage={!aboutGridImage3 ? true : false}
												/>
											</div>
										</Form.Group>
									</Col>
									<Col lg={6} md={12} sm={12} xs={12}>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="aboutGridTitle3" type="text" component={this.renderField} label={formatMessage(messages.featureTitle3)} />
											</div>
										</Form.Group>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="aboutGridContent3" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.featureDesc3)} />
											</div>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col lg={6} md={12} sm={12} xs={12} className={bt.space2}>
										<Form.Group className={s.formGroup}>
											<div className={cx(s.profileImgSection, s.profileImgWidth)}>
												<ImageUploadComponent
													subText={formatMessage(messages.maximumUploadSizeLabel)}
													defaultMessage={formatMessage(messages.chooseFile)}
													loaderName={'aboutGridImage4Loader'}
													postUrl={api.apiEndpoint + '/uploadHomepageImage'}
													loader={aboutGridImage4Loader}
													fieldName={'aboutGridImage4'}
													formName={'AboutSettingsForm'}
													imageSrc={gridImage4}
													inputContainer={'.dzInputContainerAboutImage1'}
													inputContainerClass={'dzInputContainerAboutImage1'}
													label={formatMessage(messages.aboutImage4)}
													defaultImage={!aboutGridImage4 ? true : false}
												/>
											</div>
										</Form.Group>
									</Col>
									<Col lg={6} md={12} sm={12} xs={12}>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="aboutGridTitle4" type="text" component={this.renderField} label={formatMessage(messages.featureTitle4)} />
											</div>
										</Form.Group>
										<Form.Group className={s.formGroup}>
											<div>
												<Field name="aboutGridContent4" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.featureDesc4)} />
											</div>
										</Form.Group>
									</Col>
								</Row>

								<Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL')}>
									<Form.Group className={s.formGroup}>
										<div className={s.displayInlineBlock}>
											<Loader
												type={"button"}
												label={formatMessage(messages.submitButton)}
												show={loading}
												buttonType={'submit'}
												className={cx(bt.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Col>
							</Form>
						</Card>
					</Col>
				</Row>
			</div>
		)
	}
}
AboutSettingsForm = reduxForm({
	form: 'AboutSettingsForm',
	onSubmit: submit,
	validate
})(AboutSettingsForm);
const selector = formValueSelector('AboutSettingsForm')
const mapState = (state) => ({
	aboutGridImage1: selector(state, 'aboutGridImage1'),
	aboutGridImage2: selector(state, 'aboutGridImage2'),
	aboutGridImage3: selector(state, 'aboutGridImage3'),
	aboutGridImage4: selector(state, 'aboutGridImage4'),
	loading: state.loader.AboutSettingsForm,
	aboutGridImage2Loader: state.loader.aboutGridImage2Loader,
	aboutGridImage1Loader: state.loader.aboutGridImage1Loader,
	aboutGridImage3Loader: state.loader.aboutGridImage3Loader,
	aboutGridImage4Loader: state.loader.aboutGridImage4Loader
})
const mapDispatch = {
}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AboutSettingsForm)));
