import { SubmissionError } from 'redux-form';
import fetch from 'node-fetch';

import messages from '../../../locale/messages';
import history from '../../../history';
import { setRuntimeVariable } from '../../../actions/runtime';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { getAdminUser } from '../../../actions/siteadmin/AdminUser/manageAdminUser';
import { siteSettings } from '../../../actions/siteadmin/siteSettings';

async function submit(values, dispatch) {

  const query = `query (
    $email: String!,
    $password: String!) {
    adminUserLogin (
        email: $email,
        password: $password
    ) {
        status
        token
        isSuperAdmin
        errorType
        errorMessage
    }
}`;

  const variables = {
    email: values?.email,
    password: values?.password,
  };

  dispatch(setLoaderStart('AdminLogin'));

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    }),
    credentials: 'include',
  });

  const { data } = await resp.json();

  dispatch(setLoaderComplete('AdminLogin'));
  
  if(data?.adminUserLogin?.status == 200) {
    dispatch(setRuntimeVariable({
      name: 'isAdminAuthenticated',
      value: true,
    }));

    dispatch(setRuntimeVariable({
      name: 'isSuperAdmin',
      value: data?.adminUserLogin?.isSuperAdmin
    }));
    dispatch(getAdminUser());
    dispatch(siteSettings());

    history.push('/siteadmin');
  } else if (data?.adminUserLogin?.status != 200 & data?.adminUserLogin?.errorType == "email") {
      throw new SubmissionError({ _error: messages?.emailNotExists?.defaultMessage });
  } else if (data?.adminUserLogin?.status != 200 & data?.adminUserLogin?.errorType == "password") {
      throw new SubmissionError({ _error: messages?.passwordWrong?.defaultMessage });
  } else if (data?.adminUserLogin?.errorType == "loggedIn") {

      dispatch(setRuntimeVariable({
        name: 'isAdminAuthenticated',
        value: true,
      }));

      dispatch(setRuntimeVariable({
        name: 'isSuperAdmin',
        value: data?.adminUserLogin?.isSuperAdmin
      }));
      dispatch(getAdminUser());
      dispatch(siteSettings());
      
      throw new SubmissionError({ _error: messages?.loggedIn?.defaultMessage });
  } else {
      throw new SubmissionError({ _error: data?.adminUserLogin?.errorMessage });
  }
}

export default submit;
