import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Row, Col } from 'react-bootstrap';
import history from '../../history'
import cx from 'classnames';
// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import s from './Layout.css';
import SideMenu from '../SideMenu/SideMenu';
import AdminHeader from '../AdminHeader/AdminHeader';
import Toaster from '../Toaster';
import SocketContext from '../../core/socketNotifications/SocketContext';

class AdminLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
    }
    this.handleResize = this.handleResize.bind(this);
  }

  openMenu = () => {
    this.setState({
      isOpen: 1,
    })
    if (typeof window !== undefined) {
      document.getElementsByTagName("html")[0].classList.add("scrollHidden");
    }
  }
  openClose = () => {
    this.setState({
      isOpen: 0,
    })
    if (typeof window !== undefined) {
      document.getElementsByTagName("html")[0].classList.remove("scrollHidden");
    }
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    let isBrowser;
    isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      if (this.state.isOpen === 1) {
        this.setState({
          isOpen: 1,
        })
      } else {
        this.setState({
          isOpen: 0,
        })
        document.getElementsByTagName("html")[0].classList.remove("scrollHidden");
      }
    }
  }

  render() {
    //For Highlighting Side Menu based on URL
    let location;
    if (history.location) {
      location = history.location.pathname
    }

    return (
      <SocketContext.Consumer>
        {
          socket => (
            <div>
              <Toaster />
              <AdminHeader socket={socket} isOpen={this.state.isOpen} openMenu={this.openMenu} />
              <SideMenu location={location} isOpen={this.state.isOpen} openClose={this.openClose} />
              <div className={cx(s.mainContent, 'mainContentRTL')}>
                {this.props.children}
              </div>
            </div>
          )
        }
      </SocketContext.Consumer>
    )
  }
}

export default withStyles(normalizeCss, s)(AdminLayout);
