import messages from '../../../locale/messages';
import { capacityTextLimit } from '../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values.categoryName || values?.categoryName.trim() == "") {
        errors.categoryName = messages.required;
    } else if (values?.categoryName?.length > 250) {
        errors.categoryName = messages.exceedLimit250;
    }

    if (!values.capacity && values.capacity !== 0) {
        errors.capacity = messages.required;
    } else if (!/^[0-9]+$/.test(values.capacity)) {
        errors.capacity = messages.intError;
    } else if (values?.capacity?.length > capacityTextLimit) {
        errors.capacity = messages.maxExceedLimit;
    }

    return errors;
};

export default validate