import React from "react";
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { change } from 'redux-form';
import { Button } from "react-bootstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Loader from '../Common/Loader/Loader';
import Dropzone from '../Common/Dropzone';
import ToolTipContent from "../Common/ToolTipContent";

import { updateTempImages } from "../../actions/siteadmin/TempImages/updateTempImages";
import messages from "../../locale/messages";

import TrashIcon from '../../../public/Icons/bin.svg';
import defaultIcon from '../../../public/Icons/defalutImage.svg'

import s from './ImageUploadComponent.css';
import bt from '../../components/commonStyle.css';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Faq from '../../../public/Icons/question_info.svg';

class ImageUploadComponent extends React.Component {

    handleDropzone = async (fileName, fieldName, formName) => {
        const { change, updateTempImages } = this.props;

        await change(formName, fieldName, fileName);
        if (fieldName === 'favicon' || fieldName === 'homeLogo') {
            fieldName = fieldName === 'homeLogo' ? 'siteLogo' : fieldName
            await updateTempImages('SiteSettings', fieldName, fileName)
        };
    };

    render() {

        const { imageSrc, label, defaultMessage, loader, inputContainer, inputContainerClass, loaderName } = this.props;
        const { postUrl, handleDropzone, fieldName, formName, isRemove, handleDelete, subText, image, defaultImage, toolTip } = this.props;

        return (
            <div>
                {label && <label className={cx(bt.labelText, 'tooltipLabelContainer svgImg')}>{label} {toolTip && <ToolTipContent label={toolTip} />}</label>}
                {
                    imageSrc && <Loader show={loader} type={"page"}>
                        <div className="positionRelative">
                            <div className={cx(s.backgroundImg, { [s.defaultImageBackSize]: defaultImage })} style={{ backgroundImage: `url(${imageSrc})` }} />
                            {
                                imageSrc && image && <a href={imageSrc} target="_blank" className={cx(s.viewOption, 'viewOptionRtl')}>
                                    <FormattedMessage {...messages.view} /></a>
                            }
                        </div>
                    </Loader>
                }
                {
                    isRemove && imageSrc &&
                    <Button onClick={handleDelete} className={cx(s.btnTrash, 'btnTrashRtl')}><img src={TrashIcon} alt='Delete' /> </Button>
                }

                <div>
                    <Dropzone
                        className={cx(bt.btnPrimary, 'fileNoPadding', 'btnPrimaryDark')}
                        subTextClass={s.subText}
                        subText={subText}
                        defaultMessage={defaultMessage}
                        componentConfig={{
                            iconFiletypes: ['.jpg', '.png', '.svg'],
                            multiple: false,
                            showFiletypeIcon: false,
                            postUrl
                        }}
                        loaderName={loaderName}
                        fieldName={fieldName}
                        formName={formName}
                        handleDropzone={handleDropzone ? handleDropzone : this.handleDropzone}
                        inputContainer={inputContainer}
                        inputContainerClass={inputContainerClass}
                        isLoader
                    />
                </div>
            </div>
        )
    }
}


const mapState = state => ({});

const mapDispatch = {
    updateTempImages,
    change
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(ImageUploadComponent)));  