import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';

import Link from '../../Link';
import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import updateCategoryImage from '../../../actions/siteadmin/updateCategoryImage';
import updateCategoryMarker from '../../../actions/siteadmin/updateCategoryMarker';
import messages from '../../../locale/messages';
import validate from './validate';
import { api, categoryUploadDir } from '../../../config';

import defaultIcon from '../../../../public/Icons/defalutImage.svg';

import s from './EditCategoryForm.css';
import bt from '../../../components/commonStyle.css';





export class EditCategoryForm extends Component {
    static defaultProps = {
        currency: 'USD',
        loading: false
    };
    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group>
                <label className={bt.labelText} >{label}</label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={bt.formControlInput} />
                {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }
    renderFieldDiscount = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        return (
            <div className={'inputFormAddon'}>
                <Form.Group>
                    <label className={bt.labelText} >{label}</label>
                    <InputGroup>
                        <Form.Control {...input} placeholder={placeholder} type={type} className={"formControlInputWithAddon"} />
                        <InputGroup.Append>
                            <InputGroup.Text className="postfixAppendText">%</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    {touched && error && <span className={bt.errorMessage}>{error.defaultMessage}</span>}
                </Form.Group>
            </div>
        )
    }

    handleDropzone = async (fileName, fieldName) => {
        const { id, change, updateCategoryImage, categoryImage, categoryMarkerImage, updateCategoryMarker } = this.props;
        await change(fieldName, fileName);

        switch (fieldName) {
            case 'categoryImage':
                await updateCategoryImage(id, fileName, categoryImage);
                break;
            default:
                await updateCategoryMarker(id, fileName, categoryMarkerImage);
        }
    }

    render() {
        const { handleSubmit, currency, id, categoryMarkerImage, categoryImage, loading, submitting } = this.props;
        const { categoryImageLoader, categoryMarkerImageLoader } = this.props;
        const { formatMessage } = this.props.intl;
        let categoryIcon, categoryMapMarker;
        categoryIcon = categoryImage ? api.apiEndpoint + categoryUploadDir + categoryImage : defaultIcon;
        categoryMapMarker = categoryMarkerImage ? api.apiEndpoint + categoryUploadDir + categoryMarkerImage : defaultIcon;
        return (
            <div className={cx('cardSection', s.widthInner, bt.space5, s.responsiveNoPadding, s.widthInnerTwo, 'bgBlackTwo')}>
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12} className={s.responsiveNoPadding}>
                        <Card className={s.card}>
                            <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h1 className='textAlignRightRTL'>{formatMessage(messages.editCategory)}</h1>
                                </Col>
                                <Row>
                                    <Col lg={6} md={12} sm={6} xs={12} className={bt.space2}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.centerFlex, s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'categoryImageLoader'}
                                                    postUrl={api.apiEndpoint + '/uploadCategoryImage'}
                                                    loader={categoryImageLoader}
                                                    fieldName={'categoryImage'}
                                                    imageSrc={categoryIcon}
                                                    label={formatMessage(messages.categoryIcon)}
                                                    inputContainer={'.dzInputContainerIcon'}
                                                    inputContainerClass={'dzInputContainerIcon'}
                                                    handleDropzone={this.handleDropzone}
                                                    defaultImage={!categoryImage ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={6} xs={12} className={bt.space2}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={cx(s.centerFlex, s.profileImgSection, s.profileImgWidth)}>
                                                <ImageUploadComponent
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    loaderName={'categoryMarkerImageLoader'}
                                                    postUrl={api.apiEndpoint + '/uploadCategoryMarker'}
                                                    loader={categoryMarkerImageLoader}
                                                    fieldName={'categoryMarkerImage'}
                                                    imageSrc={categoryMapMarker}
                                                    label={formatMessage(messages.categoryMapMarker)}
                                                    inputContainer={'.dzInputContainerMarker'}
                                                    inputContainerClass={'dzInputContainerMarker'}
                                                    handleDropzone={this.handleDropzone}
                                                    defaultImage={!categoryMarkerImage ? true : false}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4} md={6} sm={6} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="categoryName"
                                                    type="text"
                                                    placeholder={formatMessage(messages.categoryName)}
                                                    component={this.renderField}
                                                    label={formatMessage(messages.categoryName)}
                                                    labelClass={bt.labelText}
                                                    fieldClass={bt.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={6} sm={6} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="capacity"
                                                    type="text"
                                                    placeholder={formatMessage(messages.capacity)}
                                                    component={this.renderField}
                                                    label={formatMessage(messages.capacity)}
                                                    labelClass={bt.labelText}
                                                    fieldClass={bt.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={6} sm={6} xs={12}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <label className={bt.labelText} >{formatMessage(messages.status)}</label>
                                                <Field name="isActive" className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                                                    <option value={true}>{formatMessage(messages.active)}</option>
                                                    <option value={false}>{formatMessage(messages.inactive)}</option>
                                                </Field>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                                    <Form.Group className={s.formGroup}>
                                        <div className={s.displayInlineBlock}>
                                            <Loader
                                                type={"button"}
                                                label={formatMessage(messages.update)}
                                                show={loading}
                                                buttonType={'submit'}
                                                className={cx(bt.btnPrimary)}
                                                disabled={submitting || loading}
                                                isSuffix={true}
                                            />
                                        </div>
                                        <Link to={"/siteadmin/category"} className={cx(s.backBtn, bt.btnSecondary, 'backBtnRTL')} >{formatMessage(messages.goBack)}</Link>
                                    </Form.Group>
                                </Col>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
EditCategoryForm = reduxForm({
    form: 'EditCategoryForm',
    onSubmit: submit,
    validate
})(EditCategoryForm);
const selector = formValueSelector('EditCategoryForm');
const mapState = state => ({
    currency: state.currency,
    categoryImage: selector(state, 'categoryImage'),
    categoryMarkerImage: selector(state, 'categoryMarkerImage'),
    id: selector(state, 'id'),
    loading: state.loader.EditCategory,
    categoryImageLoader: state.loader.categoryImageLoader,
    categoryMarkerImageLoader: state.loader.categoryMarkerImageLoader
});
const mapDispatch = {
    change,
    updateCategoryImage,
    updateCategoryMarker
};
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(EditCategoryForm)));
